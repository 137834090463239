<template>
  <section class="payment-method container mb-5 pt-2">
    <div
        v-if="gifts && gifts.length"
        class="d-flex mb-2 text-right p-3 border-bottom"
    >
      <svg
          fill="#000000"
          height="24"
          width="24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 464.628 464.628"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          enable-background="new 0 0 464.628 464.628"
      >
        <path
            d="m394.33,105.596h-76.136c22.944-22.372 37.216-53.596 37.216-88.096 0-9.665-7.835-17.5-17.5-17.5-44.786,0-84.057,24.045-105.596,59.9-21.539-35.855-60.81-59.9-105.595-59.9-9.665,0-17.5,7.835-17.5,17.5 0,34.5 14.273,65.724 37.216,88.096h-76.137c-9.665,0-17.5,7.835-17.5,17.5v324.033c0,9.665 7.835,17.5 17.5,17.5h324.032c9.665,0 17.5-7.835 17.5-17.5v-324.033c0-9.665-7.835-17.5-17.5-17.5zm-17.5,162.016h-127.016v-127.016h127.016v127.016zm-58.646-230.385c-7.525,32.765-33.378,58.618-66.144,66.143 7.526-32.766 33.379-58.619 66.144-66.143zm-105.596,66.142c-32.765-7.525-58.618-33.378-66.143-66.143 32.765,7.525 58.618,33.378 66.143,66.143zm2.226,37.227v127.016h-127.016v-127.016h127.016zm-127.016,162.016h127.016v127.017h-127.016v-127.017zm162.016,127.016v-127.016h127.016v127.017h-127.016z"
        />
      </svg>
      <h6 class="fontsize-large text-color-444 mt-1 mr-1 weight-bold">
        انتخاب هدیه بندیتو:
      </h6>
    </div>
    <div id="giftSection" v-if="gifts && gifts.length">
      <div
          class="giftItem"
          v-for="(gift, index) in gifts"
          :key="index"
          @click="giftSelected(gift.id)"
          :class="{
          deactive: finalTotal < gift.min_order_amount,
          selectedGift: selectedGift == gift.id,
        }"
      >
        <span>
          <i>حداقل خرید:</i>
          <strong>{{ gift.min_order_amount | price }} تومان</strong>
        </span>
        <!-- <span>
          <i>ارزش هدیه:</i>
          <strong>{{ gift.price | price }} تومان</strong>
        </span> -->
        <img :src="gift.image" :alt="gift.title"/>

        <button>
          <svg
              fill="#000000"
              width="24"
              height="24"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
          >
            <title>checked</title>
            <path
                d="M26.5 27h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2.5v-2.5c0-0.276 0.224-0.5 0.5-0.5 0.275 0 0.5 0.224 0.5 0.5v3c0 0.276-0.225 0.5-0.5 0.5zM26.5 21c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5 0.275 0 0.5 0.224 0.5 0.5v3c0 0.276-0.225 0.5-0.5 0.5zM26.5 15c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5 0.275 0 0.5 0.224 0.5 0.5v3c0 0.276-0.225 0.5-0.5 0.5zM26.5 9c-0.276 0-0.5-0.224-0.5-0.5v-2.5h-2.5c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.275 0 0.5 0.224 0.5 0.5v3c0 0.276-0.225 0.5-0.5 0.5zM14.666 21.053c-0.184 0.185-0.483 0.185-0.668 0l-1.002-1.002c-0.002-0.003-0.001-0.007-0.003-0.009l-3.188-3.212c-0.185-0.184-0.185-0.483 0-0.668l1.002-1.003c0.185-0.185 0.484-0.185 0.669 0l2.86 2.881 6.014-6.013c0.184-0.185 0.483-0.185 0.668 0l1.002 1.003c0.186 0.185 0.186 0.484 0 0.669l-7.354 7.354zM20.5 6h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.275 0 0.5 0.224 0.5 0.5s-0.225 0.5-0.5 0.5zM14.5 6h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h3c0.275 0 0.5 0.224 0.5 0.5s-0.225 0.5-0.5 0.5zM8.5 27h-3c-0.276 0-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5s0.5 0.224 0.5 0.5v2.5h2.5c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5zM8.5 6h-2.5v2.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5h3c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5zM5.5 11c0.276 0 0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5zM5.5 17c0.276 0 0.5 0.224 0.5 0.5v3c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5v-3c0-0.276 0.224-0.5 0.5-0.5zM11.5 26h3c0.275 0 0.5 0.224 0.5 0.5s-0.225 0.5-0.5 0.5h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5zM17.5 26h3c0.275 0 0.5 0.224 0.5 0.5s-0.225 0.5-0.5 0.5h-3c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5z"
            ></path>
          </svg>
          <i></i>
          <span>
            {{ gift.title }}
          </span>
        </button>
        <p>{{ gift.description }}</p>
      </div>
    </div>
    <div class="d-flex mb-2 text-right p-3 border-bottom">
      <svg
          width="24"
          height="24"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M14.0766 1.80196H7.45705C4.14728 1.80196 3.32422 2.68631 3.32422 6.21497V16.0742C3.32422 18.4033 4.60259 18.9549 6.1524 17.2913L6.16116 17.2825C6.87915 16.5208 7.97365 16.5821 8.59532 17.4139L9.47968 18.5959C10.1889 19.5328 11.3359 19.5328 12.0452 18.5959L12.9295 17.4139C13.56 16.5733 14.6545 16.512 15.3725 17.2825C16.931 18.9462 18.2006 18.3946 18.2006 16.0655V6.21497C18.2094 2.68631 17.3863 1.80196 14.0766 1.80196ZM8.14001 6.17995C8.62159 6.17995 9.01561 6.57397 9.01561 7.05555C9.01561 7.53713 8.63035 7.93115 8.14001 7.93115C7.64968 7.93115 7.26441 7.53713 7.26441 7.05555C7.26441 6.57397 7.64968 6.17995 8.14001 6.17995ZM13.3936 12.3091C12.9033 12.3091 12.518 11.9151 12.518 11.4335C12.518 10.952 12.912 10.5579 13.3936 10.5579C13.8752 10.5579 14.2692 10.952 14.2692 11.4335C14.2692 11.9151 13.8839 12.3091 13.3936 12.3091ZM14.1816 6.69655L8.28886 12.5893C8.15752 12.7207 7.99116 12.782 7.8248 12.782C7.65843 12.782 7.49207 12.7207 7.36073 12.5893C7.10681 12.3354 7.10681 11.9151 7.36073 11.6612L13.2535 5.76842C13.5074 5.5145 13.9277 5.5145 14.1816 5.76842C14.4356 6.02234 14.4356 6.44263 14.1816 6.69655Z"
            fill="#484848"
        />
      </svg>

      <h6 class="fontsize-large text-color-444 mt-1 mr-1 weight-bold">
        انتخاب شیوه پرداخت
      </h6>
    </div>
    <div class="pt-3 order-type fontsize-medium text-justify mb-3">
      <span
      >نوع سفارش:
        <span
            @click="(e) => selectReserved(e, false)"
            class="cursor-pointer ml-3 mr-3"
        >
          <div class="align-self-center ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                  type="radio"
                  class="ml-1"
                  name="reserved"
                  :checked="!reserved"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>
          <span class="mr-3"> عادی </span>
        </span>
        <!-- <span
        class="cursor-pointer"
        @click="(e) => selectReserved(e, true)"
      >
        <div class="align-self-center ml-2 d-inline-block">
          <label class="address-checked-container">
            <input
              type="radio"
              name="reserved"
              class="ml-1"
              :checked="reserved"
            />
            <span class="address-checkmark"></span>
          </label>
        </div>
        <span class="mr-3" v-if="canBeMergedByReservations">
          اضافه کردن به
          <a
            target="_blank"
            :href="'/order/' + canBeMergedByReservations.id"
            >رزور قبلی</a
          >
        </span>
        <span class="mr-3" v-else>
          رزرو
        </span>
      </span> -->
      </span>
    </div>
    <div
        class="mb-3 show-address bg-color-themepinkyMelo border-radius15 text-justify"
    >
      <span class="text-color-themepinkytext">این سفارش به اسم </span>
      <span class="text-color-themepinkytext" style="font-weight: 900">
        {{ selectedAddress.first_name + " " + selectedAddress.last_name }}
      </span>
      <span class="text-color-themepinkytext">به آدرس </span>
      <span
          class="weight-bold text-color-themepinkytext"
          style="font-weight: 900"
      >{{ selectedAddress.address }}
      </span>
      <span class="text-color-themepinkytext">به شماره تماس </span>
      <span class="text-color-themepinkytext" style="font-weight: 900"
      >{{ selectedAddress.mobile }}
      </span>
      <span class="text-color-themepinkytext">ارسال و تحویل می گردد </span>
    </div>
    <div class="row p-3">
      <section
          class="internet-payment col-md-6 col-12 p-3 pt-4 border-radius15 bg-color-fa"
      >
        <div
            @click="(e) => selectPayMethod(e, true)"
            :style="requestingWallet ? 'opacity: 0.6' : ''"
            class="cursor-pointer d-flex align-items-center mb-3"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                  ref="radioWallet"
                  type="radio"
                  :checked="payWallet"
                  name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>

          <svg
              class="mr-3"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7689 9.87241H21.2533C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241ZM17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1555 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533ZM6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.63649C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97331 8.04087 6.31415 8.37705 6.73778 8.3818Z"
                fill="#666"
            />
          </svg>
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            <span>پرداخت از کیف پول</span>
            <span> (موجودی فعلی: {{ user.wallet.balance | price }} تومان)</span>
          </h6>
        </div>
        <div
            @click="(e) => selectPayMethod(e, false)"
            class="cursor-pointer d-flex align-items-center mb-3"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                  ref="radioGateway"
                  type="radio"
                  :checked="!payWallet"
                  name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>
          <img
              :src="require('@/assets/images/money.png')"
              class="wallet-img mr-3"
              :alt="'wallet'"
          />
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            پرداخت اینترنتی
          </h6>
        </div>
        <p class="text-color-666 fontsize-small">
          پرداخت با کلیه کارت های اعتباری شرکت
        </p>
        <Gateways
            class="mt-2 mt-md-5"
            @gatewaySelected="onGatewayClicked"
            :selected-gateway="selectedGateway"
        />
      </section>
      <section class="col-md-6 col-12 px-sm-3 px-0 pt-5">
        <div id="descriptionSection">
          <label for="orderDescription">توضیحات سفارش :</label>
          <textarea
              name=""
              v-model="orderDescription"
              id="orderDescription"
              cols="30"
              rows="4"
              class="mb-3 border-radius15"
          ></textarea>
        </div>

        <div class="pt-3 bg-color-fa border-radius15 pr-2 pb-3 pl-2">
          <div class="border-bottom">
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"
              >مجموع قیمت های کالا:
              </span>
              <span>
                <span class="fontsize-medium ml-1">{{
                    (totalPrice + totalSaving) | price
                  }}</span>
                <span class="fontsize-small text-color-999">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> تخفیف: </span>
              <span>
                <span class="ml-4" v-if="totalSaving === 0">―</span>
                <template v-else>
                  <span class="fontsize-medium ml-1 weight-bold">{{
                      totalSaving | price
                    }}</span>
                  <span class="fontsize-small">تومان</span>
                </template>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> هزینه ارسال: </span>
              <span>
                <span
                    class="fontsize-medium mr-1"
                    v-if="
                    addItem == 1 &&
                    extraShippingWithAddItem[selectedAddress.id] == 0
                  "
                >رایگان</span
                >
                <template
                    v-else-if="
                    addItem == 1 &&
                    extraShippingWithAddItem[selectedAddress.id] != undefined
                  "
                >
                  <span class="fontsize-medium ml-1">{{
                      extraShippingWithAddItem[selectedAddress.id] | price
                    }}</span>

                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
                <span
                    class="fontsize-medium mr-1"
                    v-else-if="shippingPrice === 0"
                >رایگان</span
                >
                <template v-else>
                  <span class="fontsize-medium ml-1">{{
                      shippingPrice | price
                    }}</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
              </span>
            </div>
            <div
                v-if="discount && discount.discount > 0"
                class="text-color-444 d-flex justify-content-between mb-2"
            >
              <span class="fontsize-medium weight-bold text-color-discount">
                تخفیف از کوپن:
              </span>
              <span class="text-color-discount">
                <span class="fontsize-medium ml-1 weight-bold">{{
                    discount.discount | price
                  }}</span>
                <span class="fontsize-small">تومان</span>
              </span>
            </div>
          </div>
          <div
              class="text-color-themeGreen d-flex justify-content-between mt-2"
          >
            <span class="fontsize-medium weight-bold"> پرداخت نهایی: </span>
            <span class="">
              <span class="weight-bold fontsize-large ml-1">{{
                  finalTotal | price
                }}</span>
              <span class="fontsize-medium">تومان</span>
            </span>
          </div>
          <template v-if="isPayingHalfHalf">
            <div class="text-color-444 d-flex justify-content-between mt-2">
              <span class="fontsize-medium weight-bold">
                پرداخت از کیف پول:
              </span>
              <span class="text-color-theme">
                <span class="weight-bold fontsize-large ml-1">{{
                    user.wallet.balance | price
                  }}</span>
                <span class="fontsize-medium">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mt-2">
              <span class="fontsize-medium weight-bold">
                پرداخت از درگاه:
              </span>
              <span class="text-color-theme">
                <span class="weight-bold fontsize-large ml-1 text-danger">{{
                    (finalTotal - user.wallet.balance) | price
                  }}</span>
                <span class="fontsize-medium">تومان</span>
              </span>
            </div>
          </template>
        </div>
      </section>
    </div>
    <div
        class="d-flex next-step-button-container position-sticky justify-content-between align-items-baseline py-3 px-sm-3 px-0"
    >
      <!-- <p class="fontsize13 text-color-666 d-md-block d-none">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
      </p> -->
      <button
          id="prev-btn-step-3"
          class="continue-buy-process py-2 w-25 ml-2 fontsize-medium text-center d-none d-md-block"
          @click="prevPage"
      >
        <ZArrow/>
        مرحله قبل
      </button>
      <button
          class="continue-buy-process py-2 w-25 text-center fontsize-medium"
          :disabled="
          waitingForPayStatus != 'init' && waitingForPayStatus != 'error'
        "
          @click="acceptTherems()"
      >
        <template v-if="waitingForPayStatus == 'init'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'loading'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'redirecting'"
        >درحال انتقال به درگاه
        </template
        >
        <template v-else-if="waitingForPayStatus == 'error'"
        >تلاش مجدد
        </template
        >
      </button>
    </div>
    <div v-if="showTheremsModal" id="theremsModal">
      <div id="content">
        <p class="fontsize-mini">
          دوستان و همکاران عزیز به دليل توليد روزانه محصولات و همچنین بسته بندی
          نهایی با بالاترین کیفیت ، کلیه سفارشات پس از حدود 7 تا 10 روز کاری به اداره پست تحویل داده میشود ( سفارشات
          گرگان یک روز پس از ثبت
          سفارش با پیک فروشگاه ارسال میشود )
          <br/>
          پس از ارسال ، لینک كدرهگيري پستی از طریق پیامک برای شما عزیزان ارسال
          خواهد شد .
          <br/>
          ممنون از شکیبایی شما
        </p>
        <div id="checkedAccept">
          <input id="acceptTerms" type="checkbox" v-model="accept"/>
          <label for="acceptTerms">موارد فوق مورد تایید است</label>
        </div>

        <div id="buttons">
          <button @click="showTheremsModal = false" id="cnclBtn">لغو</button>
          <button
              :class="{ disable: accept != true }"
              id="accBtn"
              @click="acceptAndTherme()"
          >
            تایید
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Gateways from "../shared/Gateways";
import ZArrow from "@/parts/Front/components/shared/ZArrow";

export default {
  name: "CartStep3",
  components: {ZArrow, Gateways},
  data() {
    return {
      accept: false,
      showTheremsModal: false,
      requestingWallet: false,
      orderDescription: null,
      selectedGift: null,
    };
  },
  props: {
    addItem: Number,
    gateways: Array,
    selectedGateway: Object,
    selectedAddress: Object,
    discount: Object,
    shippingPrice: Number,
    total: Number,
    totalSaving: Number,
    totalPrice: Number,
    payWallet: Boolean,
    reserved: Boolean,
    waitingForPayStatus: String,
    finalTotal: Number,
    reservations: Array,
    selectedShipping: Object,
    gifts: Array,
    canBeMergedByReservations: [Object, Boolean],
  },
  mounted() {
    // Select default
    if (this.gateways && this.gateways.length && !this.selectedGateway) {
      this.$emit("gatewaySelected", this.gateways[0]);
    }
  },
  computed: {
    extraShippingWithAddItem() {
      return this.$store.getters["front/getExtraShippingWithAddItem"];
    },
    user() {
      return this.homeData.user.user;
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    isPayingHalfHalf() {
      return this.payWallet && this.finalTotal > this.user.wallet.balance;
    },
  },
  methods: {
    giftSelected(id) {
      if (this.selectedGift == id) {
        this.selectedGift = null;
      } else {
        this.selectedGift = id;
      }
    },
    acceptAndTherme() {
      this.showTheremsModal = false;
      this.pay();
    },
    acceptTherems() {
      this.showTheremsModal = true;
    },
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    onGatewayClicked(gateway) {
      this.$emit("gatewaySelected", gateway);
    },
    async pay() {
      try {
        let response = await this.$axios.get("getSystemTime");
        if (response.data.data.data && response.data.data.data.result) {
          this.$emit("pay", {
            orderDescription: this.orderDescription,
            selectedGift: this.selectedGift,
          });
        } else {
          window.swal({
            title: response.data.data.data.message,
            icon: "warning",
            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          });
        }
      } catch (error) {
        this.$root.notify(error);
      }
    },
    async selectPayMethod(e, wallet) {
      if (this.requestingWallet) {
        return;
      }
      if (wallet) {
        this.$emit("payMethodSelected", true);

        if (this.user.wallet.balance < this.finalTotal) {
          this.requestingWallet = true;
          try {
            const response = await this.$axios.get("customer/get-balance");
            this.user.wallet.balance = response.data;
          } catch (e) {
            this.$root.notify(e);
          } finally {
            this.requestingWallet = false;
          }
        }
      } else {
        this.$refs.radioGateway.click();
        this.$emit("payMethodSelected", false);
      }
    },

    selectReserved(e, reserved) {
      this.$emit("reserveSelected", reserved);
    },
  },
};
</script>
<style scoped>
.next-step-button-container{
  bottom: 10px;
}
.show-address {
  padding: 26px 8px;
  font-size: 15px;
}

.continue-buy-process {
  height: 50px !important;
  border-radius: 16px;
}

.wallet-img {
  width: 27px;
  height: 24px;
}

.order-type .address-checkmark,
.order-type .address-checkmark::after {
  border-radius: 25px;
}

#prev-btn-step-3 {
  background: var(--color-brown-theme);
  color: white !important;
}

#theremsModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000ba;
  display: flex;
}

div#content {
  width: 40%;
  background: white;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
}

div#content p {
  font-size: 16px;
  line-height: 40px;
}

div#checkedAccept {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

div#checkedAccept input {
  width: 30px;
  height: 30px;
}

div#checkedAccept label {
  margin: 0;
}

div#buttons button {
  padding: 10px 35px;
  border-radius: 5px;
  color: white;
}

div#buttons {
  display: flex;
  gap: 20px;
  width: 80%;
  margin: auto;
  justify-content: center;
}

div#buttons #accBtn {
  /* background: blue; */
  background: var(--color-theme);
}

div#buttons #cnclBtn {
  background: red;
}

div#buttons .disable {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 768px) {
  div#content {
    width: 90%;
  }

  div#content p {
    font-size: 16px;
    text-align: justify;
    line-height: normal;
  }
}

div#descriptionSection textarea {
  border: 1px solid #1213;
  resize: none;
  margin-bottom: 13px;
  padding: 8px;
}

div#descriptionSection label {
  font-size: 18px;
}

div#descriptionSection {
  display: flex;
  flex-direction: column;
}

div#giftSection {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

div#giftSection div button {
  border: 1px solid #e3e3e3;
  background-color: #e3e3e3;
  min-width: 120px;
  padding: 8px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 16px;
}

div#giftSection div {
  display: flex;
  align-items: center;
  gap: 16px;
  border: 2px dashed #e3e3e3;
  margin-bottom: 16px;
  flex-direction: column;
  width: max-content;
  padding: 16px;
}

div#giftSection div p {
  margin-bottom: 0;
}

div#giftSection div button i {
  width: 18px;
  height: 18px;
  border: 1px dashed black;
}

div#giftSection div button svg {
  display: none;
}

div#giftSection div.selectedGift button svg {
  display: block;
}

div#giftSection div.selectedGift button i {
  display: none;
}

div#giftSection div.selectedGift button svg {
  fill: white;
}

div#giftSection div.selectedGift button {
  background-color: var(--color-theme);
  color: white;
}

div#giftSection div.selectedGift {
  border: 2px dashed var(--color-theme);
}

div#giftSection {
  padding: 24px 0;
}

div#giftSection .deactive {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

div#giftSection div.selectedGift[data-v-4dbb3c9a] {
  border: 2px dashed var(--color-theme);
}

.giftItem img {
  width: 150px;
}
</style>
