<template>
  <div id="link">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        لینک معرف در بندیتو
      </h3>
    </div>
    <div class="h-100 w-100 mt-4">
      <h5>برای دریافت لینک شخصی خود در بندیتو دکمه زیر را فشار دهید</h5>
      <div id="linkBox">
        <button id="copyLink" @click="copyLink()">کپی لینک</button>
        <strong v-if="user">
          {{ user.invite_link }}
        </strong>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["clubData", "clubColor"],
  methods: {
    copyLink() {
      if (!this.user) return;
      try {
        navigator.clipboard.writeText(this.user.invite_link);
        this.$root.notify("لینک با موفقیت کپی شد", "success");
      } catch (error) {
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters["front/getUserDetail"];
    },
  },
};
</script>
<style scoped>
.club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
</style>
<style>
#link {
  --rp: 8px;
}
#scores table tbody tr td,
#scores table thead tr th {
  white-space: nowrap !important;
}
#copyLink {
  background: v-bind(clubColor);
  color: white;
  padding: var(--rp) 32px;
  border-radius: var(--rp);
}
div#linkBox strong {
  background: #e3e3e3;
  padding: var(--rp);
  border-radius: var(--rp);
}
div#linkBox {
  border: 1px dotted;
  padding: var(--rp);
  width: max-content;
  border-radius: var(--rp);
  display: flex;
  align-items: center;
  gap: 12px;
}
@media (max-width: 580px) {
  #link {
    --rp: 4px;
  }
  #copyLink {
    padding: var(--rp) 16px;
    border-radius: var(--rp);
    font-size: 14px;
  }
  div#linkBox strong {
    font-size: 10px;
    border-radius: var(--rp);
    padding: 6px var(--rp);
  }
  div#linkBox {
    border: 1px dotted;
    padding: var(--rp);
    border-radius: var(--rp);
    gap: 8px;
  }
}
</style>
