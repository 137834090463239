<template>
  <form
    @submit.prevent="submit"
    :id="'form-' + parentId"
    class="mb-2"
    :class="{ 'mb-0': !parentId, 'mb-xl-4': parentId }"
  >
    <div
      class="d-flex flex-lg-row flex-column flex-wrap justify-content-between fontsize-small form-group"
    >
      <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
      <input
        required
        v-else
        id="fullNameComment"
        v-model="fullName"
        name="name"
        class="pr-3 py-3 border-r-20 text-grey-8b"
        placeholder="نام کامل*"
        type="text"
      />
      <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
      <input
        required
        v-else
        id="emailComment"
        v-model="email"
        class="pr-3 py-3 border-r-20 text-grey-8b mt-2 mt-lg-0"
        placeholder=" آدرس ایمیل*"
        type="email"
        name="email"
      />
    </div>
    <b-skeleton v-if="blogStatus" type="input"></b-skeleton>
    <textarea
      v-model="body"
      id="textComment"
      v-else
      class="pr-3 py-3 border-r-20 text-grey-8b w-100 mb-2 fontsize-small"
      name="body"
      cols="30"
      rows="9"
      placeholder="دیدگاه شما*"
    ></textarea>
    <p class="text-danger mb-1 pr-4 comment-error">{{ commentError }}</p>
    <div class="send-comment-btn-container text-left">
      <b-skeleton v-if="blogStatus" type="button"></b-skeleton>
      <div v-else class="position-relative">
        <Loading class="comment-loading" v-if="disabled" />

        <div class="d-flex pr-4 pl-3 py-2 align-items-center bg-color-theme text-white fontsize-small weight-bold gap-10 border-r-10">
          <input
            :disabled="disabled"
            class="bg-none text-light w-100"
            type="submit"
            value="ارسال نظر"
          />
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.54102 10.2285H16.041"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.58268 15.2489L3.54102 10.2288L8.58268 5.20801"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import Loading from "@/parts/Front/components/shared/Loading";
import { BSkeleton } from "bootstrap-vue";

export default {
  name: "CommentForm",
  components: { Loading, BSkeleton },
  props: {
    parentId: {
      type: [Number, String],
      default: null,
    },
    blogId: [Number, String],
  },
  data() {
    return {
      fullName: "",
      email: "",
      body: "",
      disabled: false,
      commentError: "",
    };
  },
  computed: {
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
  },
  created() {
    if (
      window.backup_comment_form_component &&
      window.backup_comment_form_component.id === this.parentId
    ) {
      window.backup_comment_form_component.data.forEach(
        (val, index) => (this.$data[index] = val)
      );
    }
  },
  methods: {
    async submit() {
      if (this.body === "") {
        this.commentError = "وارد کردن متن الزامی است";
        return;
      }
      this.disabled = true;
      try {
       
        const response = await this.$axios.post(
          `front/posts/${this.blogId}/comments`,
          {
            name: this.fullName,
            email: this.email,
            body: this.body,
            parent_id: this.parentId ? this.parentId : undefined,
          }
        );
        this.$root.notify(response.data.message, "success");
        this.resetData();
        this.$emit("commentAdded", response.data.data.comment);
      } catch (e) {
        this.$root.notify(e);
      } finally {
        this.disabled = false;
      }
    },
    resetData() {
      let initialData = this.$options.data.apply(this);
      for (let [index] of Object.entries(this.$data)) {
        this.$data[index] = initialData[index];
      }
    },
  },
  beforeDestroy() {
    // اگه دستش خورد بسته شد دوباره باز شد بیاد
    window.backup_comment_form_component = {
      id: this.parentId,
      data: cloneDeep(this.$data),
    };
  },
};
</script>

<style scoped>
form input[type="text"],
form input[type="email"],
textarea {
  transition: 200ms all;
}

form input[type="text"]:focus,
form input[type="email"]:focus,
textarea:focus {
  box-shadow: 0 0 1px 1px #745d45;
}

form input[type="submit"]:disabled,
form button:disabled {
  opacity: 0.6;
}

.send-comment-btn {
  width: 100%;
  height: 40px;
}

.send-comment-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.send-comment-btn-container .comment-loading {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
}
.w-60 {
  width: 60% !important;
}
.comment-error {
  position: absolute;
}
@media screen and (max-width: 768px) {
  .comment-error {
    position: relative;
  }
}
@media only screen and (min-width: 768px) {
  .comment-error {
    position: absolute;
  }
  .w-md-25 {
    width: 25% !important;
  }
}
</style>
