<template>
  <div class="cart-page">
    <PagesPath
      v-if="!mediaQueries.large"
      :pathTitle1="'محصولات'"
      :pathTo1="'/products'"
      :pathTitle2="'تسویه حساب'"
    />
    <div>
      <div
        class="container container-md d-flex d-lg-none justify-content-center pt-4 px-4 pb-3 align-items-center position-relative"
      >
        <div class="move-to-step">
          <button @click="back"></button>
        </div>
        <router-link to="/">
          <figure class="m-0">
            <img src="@/assets/images/logo.png" alt="بندیتو" />
          </figure>
        </router-link>
      </div>
      <section
        class="cart-top d-md-flex d-none container box-shaddow20 justify-content-center bg-color-white p-4 border-r-25 align-items-baseline fontsize-medium text-color-444"
      >
        <div
          @click="goToStep1"
          class="cursor-pointer"
          :class="activeTab == 1 ? 'cart-top-complete' : ''"
        >
          <svg
            class="ml-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6203 6.474H16.4136C16.3872 4.003 14.4242 2 12.0117 2C10.7967 2.017 9.723 2.46 8.88729 3.311C8.06231 4.149 7.60398 5.265 7.58643 6.474H7.3797C4.68923 6.474 2.5 8.719 2.5 11.479V16.995C2.5 19.755 4.68923 22 7.3797 22C7.78341 22 8.11107 21.664 8.11107 21.25C8.11107 20.836 7.78341 20.5 7.3797 20.5C5.49569 20.5 3.96274 18.928 3.96274 16.995V11.479C3.96274 9.546 5.49569 7.974 7.3797 7.974H7.58155V9.555C7.58155 9.969 7.90921 10.305 8.31292 10.305C8.71664 10.305 9.04429 9.969 9.04429 9.555V6.509C9.04722 5.702 9.35732 4.944 9.91608 4.375C10.4768 3.808 11.2247 3.545 12.0088 3.5C13.6168 3.5 14.9245 4.83 14.9508 6.474H11.7513C11.3476 6.474 11.02 6.81 11.02 7.224C11.02 7.638 11.3476 7.974 11.7513 7.974H14.9557V9.555C14.9557 9.969 15.2834 10.305 15.6871 10.305C16.0908 10.305 16.4184 9.969 16.4184 9.555V7.974H16.6203C18.5043 7.974 20.0373 9.546 20.0373 11.479V16.995C20.0373 18.928 18.5043 20.5 16.6203 20.5H11.3632C10.9595 20.5 10.6319 20.836 10.6319 21.25C10.6319 21.664 10.9595 22 11.3632 22H16.6203C19.3108 22 21.5 19.755 21.5 16.995V11.479C21.5 8.719 19.3108 6.474 16.6203 6.474Z"
              fill="#444"
            />
          </svg>
          <span>سبد خرید</span>
          <span class="mx-3">-----</span>
        </div>
        <div
          @click="goToStep2"
          class="cursor-pointer"
          :class="activeTab == 2 ? 'cart-top-complete' : ''"
        >
          <svg
            class="ml-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9999 2H12.0333C16.7197 2.01842 20.5183 5.84197 20.4999 10.5223V10.6153C20.4366 13.96 18.4258 16.7356 16.7504 18.4746C16.2736 18.9718 15.7661 19.4489 15.2427 19.8944C14.9292 20.1619 14.4586 20.1251 14.1908 19.812C13.923 19.498 13.9607 19.028 14.2742 18.7605C14.7589 18.3475 15.2304 17.9037 15.6739 17.4416C17.1649 15.8946 18.9527 13.4487 19.0071 10.5881C19.022 6.65842 15.8916 3.50574 12.0272 3.48996H11.9999C8.14862 3.48996 5.00768 6.61194 4.99276 10.4609C5.05861 12.3622 5.70138 14.1696 6.85256 15.6859C8.19604 17.4749 10.2341 19.3691 12.1712 20.6302C12.5171 20.8547 12.6146 21.316 12.3889 21.6615C12.2467 21.8807 12.007 22 11.7628 22C11.6232 22 11.4827 21.9614 11.3563 21.879C9.28224 20.5293 7.0993 18.5 5.66011 16.5839C4.32453 14.8238 3.57727 12.7235 3.5 10.5127C3.51844 5.78585 7.32848 2 11.9999 2ZM10.2552 10.6141C10.2552 11.5727 11.0367 12.354 11.9973 12.354C12.9579 12.354 13.7394 11.5727 13.7394 10.6141C13.7394 9.65474 12.9579 8.87337 11.9973 8.87337C11.5855 8.87337 11.2509 8.54012 11.2509 8.12883C11.2509 7.71665 11.5855 7.38341 11.9973 7.38341C13.7807 7.38341 15.2322 8.83215 15.2322 10.6141C15.2322 12.3952 13.7807 13.844 11.9973 13.844C10.2139 13.844 8.76241 12.3952 8.76241 10.6141C8.76241 10.202 9.09696 9.86872 9.50879 9.86872C9.92061 9.86872 10.2552 10.202 10.2552 10.6141Z"
              fill="#444"
            />
          </svg>

          <span> اطلاعات ارسال</span>
          <span class="mx-3">-----</span>
        </div>
        <div
          @click="goToStep3"
          class="cursor-pointer"
          :class="activeTab == 3 ? 'cart-top-complete' : ''"
        >
          <svg
            class="ml-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.78489 2.5H16.2142C19.4044 2.5 22 5.15478 22 8.41891V15.5811C22 18.8452 19.4044 21.5 16.2142 21.5C15.8462 21.5 15.5476 21.1944 15.5476 20.8179C15.5476 20.4414 15.8462 20.1358 16.2142 20.1358C18.6693 20.1358 20.6667 18.0931 20.6667 15.5811V9.86499H17.3831C16.3049 9.8659 15.4258 10.7645 15.4249 11.8686C15.4258 12.9727 16.3049 13.8713 17.3831 13.8722H18.7476C19.1156 13.8722 19.4142 14.1778 19.4142 14.5543C19.4142 14.9308 19.1156 15.2364 18.7476 15.2364H17.3831C15.5689 15.2355 14.0924 13.7248 14.0916 11.8686C14.0924 10.0123 15.5689 8.50168 17.3831 8.50077H20.6667V8.41891C20.6667 5.90692 18.6693 3.86422 16.2142 3.86422H7.78489C5.80622 3.86422 4.14578 5.19934 3.56711 7.02831H12.3547C12.7227 7.02831 13.0213 7.3339 13.0213 7.71043C13.0213 8.08786 12.7227 8.39254 12.3547 8.39254H3.336C3.336 8.39709 3.33533 8.40141 3.33467 8.40573C3.334 8.41004 3.33333 8.41436 3.33333 8.41891V15.5811C3.33333 18.0931 5.32978 20.1358 7.78489 20.1358H12.0258C12.3938 20.1358 12.6924 20.4414 12.6924 20.8179C12.6924 21.1944 12.3938 21.5 12.0258 21.5H7.78489C4.59467 21.5 2 18.8452 2 15.5811V8.41891C2 5.15478 4.59467 2.5 7.78489 2.5ZM16.861 11.8071C16.861 11.4306 17.1596 11.125 17.5276 11.125H17.8308C18.1988 11.125 18.4974 11.4306 18.4974 11.8071C18.4974 12.1836 18.1988 12.4892 17.8308 12.4892H17.5276C17.1596 12.4892 16.861 12.1836 16.861 11.8071Z"
              fill="#444"
            />
          </svg>

          <span> اطلاعات پرداخت</span>
        </div>
      </section>
      <section
        class="cart-top2 d-md-none d-flex justify-content-center align-items-baseline mb-4 my-sm-0 fontsize14 text-color-444"
      >
        <div
          @click="goToStep1"
          class="d-flex align-items-baseline"
          :class="activeTab == 1 ? 'cart-top-complete' : ''"
        >
          <div class="d-flex flex-column align-items-center">
            <svg
              class="ml-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6203 6.474H16.4136C16.3872 4.003 14.4242 2 12.0117 2C10.7967 2.017 9.723 2.46 8.88729 3.311C8.06231 4.149 7.60398 5.265 7.58643 6.474H7.3797C4.68923 6.474 2.5 8.719 2.5 11.479V16.995C2.5 19.755 4.68923 22 7.3797 22C7.78341 22 8.11107 21.664 8.11107 21.25C8.11107 20.836 7.78341 20.5 7.3797 20.5C5.49569 20.5 3.96274 18.928 3.96274 16.995V11.479C3.96274 9.546 5.49569 7.974 7.3797 7.974H7.58155V9.555C7.58155 9.969 7.90921 10.305 8.31292 10.305C8.71664 10.305 9.04429 9.969 9.04429 9.555V6.509C9.04722 5.702 9.35732 4.944 9.91608 4.375C10.4768 3.808 11.2247 3.545 12.0088 3.5C13.6168 3.5 14.9245 4.83 14.9508 6.474H11.7513C11.3476 6.474 11.02 6.81 11.02 7.224C11.02 7.638 11.3476 7.974 11.7513 7.974H14.9557V9.555C14.9557 9.969 15.2834 10.305 15.6871 10.305C16.0908 10.305 16.4184 9.969 16.4184 9.555V7.974H16.6203C18.5043 7.974 20.0373 9.546 20.0373 11.479V16.995C20.0373 18.928 18.5043 20.5 16.6203 20.5H11.3632C10.9595 20.5 10.6319 20.836 10.6319 21.25C10.6319 21.664 10.9595 22 11.3632 22H16.6203C19.3108 22 21.5 19.755 21.5 16.995V11.479C21.5 8.719 19.3108 6.474 16.6203 6.474Z"
                fill="#444"
              />
            </svg>
            <span>سبد خرید</span>
          </div>

          <span class="mx-2">-----</span>
        </div>
        <div
          @click="goToStep2"
          class="d-flex align-items-baseline"
          :class="activeTab == 2 ? 'cart-top-complete' : ''"
        >
          <div class="d-flex flex-column align-items-center">
            <svg
              class="ml-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.9999 2H12.0333C16.7197 2.01842 20.5183 5.84197 20.4999 10.5223V10.6153C20.4366 13.96 18.4258 16.7356 16.7504 18.4746C16.2736 18.9718 15.7661 19.4489 15.2427 19.8944C14.9292 20.1619 14.4586 20.1251 14.1908 19.812C13.923 19.498 13.9607 19.028 14.2742 18.7605C14.7589 18.3475 15.2304 17.9037 15.6739 17.4416C17.1649 15.8946 18.9527 13.4487 19.0071 10.5881C19.022 6.65842 15.8916 3.50574 12.0272 3.48996H11.9999C8.14862 3.48996 5.00768 6.61194 4.99276 10.4609C5.05861 12.3622 5.70138 14.1696 6.85256 15.6859C8.19604 17.4749 10.2341 19.3691 12.1712 20.6302C12.5171 20.8547 12.6146 21.316 12.3889 21.6615C12.2467 21.8807 12.007 22 11.7628 22C11.6232 22 11.4827 21.9614 11.3563 21.879C9.28224 20.5293 7.0993 18.5 5.66011 16.5839C4.32453 14.8238 3.57727 12.7235 3.5 10.5127C3.51844 5.78585 7.32848 2 11.9999 2ZM10.2552 10.6141C10.2552 11.5727 11.0367 12.354 11.9973 12.354C12.9579 12.354 13.7394 11.5727 13.7394 10.6141C13.7394 9.65474 12.9579 8.87337 11.9973 8.87337C11.5855 8.87337 11.2509 8.54012 11.2509 8.12883C11.2509 7.71665 11.5855 7.38341 11.9973 7.38341C13.7807 7.38341 15.2322 8.83215 15.2322 10.6141C15.2322 12.3952 13.7807 13.844 11.9973 13.844C10.2139 13.844 8.76241 12.3952 8.76241 10.6141C8.76241 10.202 9.09696 9.86872 9.50879 9.86872C9.92061 9.86872 10.2552 10.202 10.2552 10.6141Z"
                fill="#444"
              />
            </svg>
            <span> اطلاعات ارسال</span>
          </div>
          <span class="mx-2">-----</span>
        </div>
        <div
          @click="goToStep3"
          class="d-flex align-items-baseline"
          :class="activeTab == 3 ? 'cart-top-complete' : ''"
        >
          <div class="d-flex flex-column align-items-center">
            <svg
              class="ml-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.78489 2.5H16.2142C19.4044 2.5 22 5.15478 22 8.41891V15.5811C22 18.8452 19.4044 21.5 16.2142 21.5C15.8462 21.5 15.5476 21.1944 15.5476 20.8179C15.5476 20.4414 15.8462 20.1358 16.2142 20.1358C18.6693 20.1358 20.6667 18.0931 20.6667 15.5811V9.86499H17.3831C16.3049 9.8659 15.4258 10.7645 15.4249 11.8686C15.4258 12.9727 16.3049 13.8713 17.3831 13.8722H18.7476C19.1156 13.8722 19.4142 14.1778 19.4142 14.5543C19.4142 14.9308 19.1156 15.2364 18.7476 15.2364H17.3831C15.5689 15.2355 14.0924 13.7248 14.0916 11.8686C14.0924 10.0123 15.5689 8.50168 17.3831 8.50077H20.6667V8.41891C20.6667 5.90692 18.6693 3.86422 16.2142 3.86422H7.78489C5.80622 3.86422 4.14578 5.19934 3.56711 7.02831H12.3547C12.7227 7.02831 13.0213 7.3339 13.0213 7.71043C13.0213 8.08786 12.7227 8.39254 12.3547 8.39254H3.336C3.336 8.39709 3.33533 8.40141 3.33467 8.40573C3.334 8.41004 3.33333 8.41436 3.33333 8.41891V15.5811C3.33333 18.0931 5.32978 20.1358 7.78489 20.1358H12.0258C12.3938 20.1358 12.6924 20.4414 12.6924 20.8179C12.6924 21.1944 12.3938 21.5 12.0258 21.5H7.78489C4.59467 21.5 2 18.8452 2 15.5811V8.41891C2 5.15478 4.59467 2.5 7.78489 2.5ZM16.861 11.8071C16.861 11.4306 17.1596 11.125 17.5276 11.125H17.8308C18.1988 11.125 18.4974 11.4306 18.4974 11.8071C18.4974 12.1836 18.1988 12.4892 17.8308 12.4892H17.5276C17.1596 12.4892 16.861 12.1836 16.861 11.8071Z"
                fill="#444"
              />
            </svg>
            <span> اطلاعات پرداخت</span>
          </div>
        </div>
      </section>
      <div class="container border-r-25 bg-color-white mt-3">
        <CartStep1
          :homeData="homeData"
          @setFreeShipping="(e) => (freeShpping = e)"
          @setReservations="setReservations"
          @couponApplied="onCouponApplied"
          :coupon-code-prop="couponCode"
          :discount="discount"
          @discountApplied="onDiscountApplied"
          @shippingsLoaded="onShippingsLoaded"
          @gatewaysLoaded="onGatewaysLoaded"
          @cartChecked="cartChecked"
          :is-cart-checked="isCartChecked"
          v-if="activeTab == 1"
          @next-page="changePage('next')"
        />
        <CartStep2
          :total-quantity="totalQuantity"
          :freeShpping="freeShpping"
          @shippingSelected="onShippingSelected"
          @lastOrderCheck="lastOrderCheck"
          :selected-shipping="selectedShipping"
          @shippingsLoaded="onShippingsLoaded"
          v-model="selectedAddress"
          :shippings="shippings"
          v-if="activeTab == 2"
          @next-page="changePage('next')"
          @prev-page="changePage('prev')"
          :total="total - (discount ? discount.discount : 0)"
          :totalWeight="totalWeight"
        />
        <CartStep3
          :can-be-merged-by-reservations="canBeMergedByReservations"
          :selected-shipping="selectedShipping"
          :reservations="reservations"
          :waitingForPayStatus="waitingForPayStatus"
          @reserveSelected="onReserveSelected"
          :reserved="reserved"
          @payMethodSelected="onPayMethodSelected"
          :payWallet="payWallet"
          @pay="pay"
          :total="total"
          :total-price="totalPrice"
          :total-saving="totalSaving"
          :shipping-price="shippingPrice"
          :discount="discount"
          v-if="activeTab == 3"
          :selected-address="selectedAddress"
          @gatewaySelected="onGatewaySelected"
          :gateways="gateways"
          :selected-gateway="selectedGateway"
          @next-page="changePage('next')"
          @prev-page="changePage('prev')"
          :finalTotal="finalTotal"
          :totalWeight="totalWeight"
          :addItem="addItem"
          :gifts="gifts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import CartStep1 from "@/parts/Front/components/cart/CartStep1";
import CartStep2 from "@/parts/Front/components/cart/CartStep2";
import CartStep3 from "@/parts/Front/components/cart/CartStep3";
import { getShippingPrice, pay } from "@/libraries/functions";
import config from "../../../config.js";

export default {
  name: "Cart",
  inject: ["mediaQueries"],
  components: {
    PagesPath,
    CartStep1,
    CartStep2,
    CartStep3,
  },
  data() {
    return {
      activeTab: 1,
      freeShpping: false,
      selectedAddress: null,
      selectedShipping: null,
      selectedGateway: null,
      shippings: null,
      gateways: null,
      isCartChecked: null, // check for warnings only once
      waitingForPayStatus: "init",
      couponCode: null,
      payWallet: false,
      reserved: false,
      reservations: [],
      addItem: 0,
      gifts: [],
    };
  },
  watch: {
    isLogin(newValue, oldVlaue) {
      if (newValue) {
        this.$axios.get("customer/order-gift-ranges").then((res) => {
          this.gifts = res.data.data.gifts;
        });
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters["front/getUserInfo"];
    },
    extraShipping() {
      return this.$store.getters["front/getHasExtraWeight"];
    },
    user() {
      return this.homeData.user.user;
    },
    discount() {
      return this.$store.getters["front/getDiscountCoupen"];
    },
    extraShippingWithAddItem() {
      return this.$store.getters["front/getExtraShippingWithAddItem"];
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    // آیا با مرج میشه رزورش کرد یا نه
    // پیثغام مناسب نشون بدیم
    canBeMergedByReservations() {
      // Reservation az jense Order
      for (let [index, reservation] of Object.entries(this.reservations)) {
        if (
          reservation.shipping_id === this.selectedShipping.id &&
          this.selectedAddress.id === reservation.address_id
        ) {
          return reservation;
        }
      }

      return false;
    },
    totalQuantity() {
      if (!this.cart) {
        return 0;
      }
      return this.cart.cartItems.reduce((total, cartItem) => {
        return total + cartItem.quantity;
      }, 0);
    },
    shippingPrice() {
      if (this.freeShpping) return 0;
      let reservation =
        this.reserved && this.canBeMergedByReservations
          ? this.canBeMergedByReservations
          : null;
      if (
        this.userInfo &&
        this.userInfo.customers_club_level &&
        this.total >
          config.shippingLevelPrice[this.userInfo.customers_club_level.id - 1]
      ) {
        return 0;
      } else
        return this.selectedShipping
          ? getShippingPrice(
              this.selectedAddress,
              this.selectedShipping,
              this.totalWeight,
              this.totalQuantity,
              this.reserved,
              reservation,
              this.homeData ? this.homeData.settings : null,
              this.total,
              this.addItem,
              this.extraShipping
            )
          : 0;
    },
    totalSaving() {
      return this.cart ? this.cart.getTotalSaving() : 0;
    },
    totalPrice() {
      return this.cart ? this.cart.getTotalPrice() : 0;
    },
    totalWeight() {
      return this.cart
        ? this.cart.getTotalWeight(
            [],
            this.homeData
              ? this.homeData.settings.shipping_amount.defualt_product_weight
              : 120
          )
        : 0;
    },
    total() {
      return this.cart ? this.cart.getTotalPrice() : 0;
    },
    // جمع توتال با هزینه ارسال منهای تخفیف از کد تخفیف
    finalTotal() {
      let shippingPrice = 0;
      if (this.addItem == 1) {
        shippingPrice =
          this.extraShippingWithAddItem[this.selectedAddress.id] == undefined
            ? 0
            : this.extraShippingWithAddItem[this.selectedAddress.id];
      } else {
        shippingPrice = this.shippingPrice;
      }
      return (
        this.total +
        shippingPrice -
        (this.discount ? this.discount.discount : 0)
      );
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  methods: {
    back() {
      if (this.activeTab > 1) {
        this.activeTab -= 1;
      } else {
        this.activeTab = 1;
        this.$router.go(-1);
      }
    },
    cartChecked(checked) {
      this.isCartChecked = checked;
    },
    changePage(item) {
      if (item == "next") {
        this.activeTab = this.activeTab + 1;
      } else if (item == "prev") {
        this.activeTab = this.activeTab - 1;
      }

      setTimeout(() => {
        document.querySelector(".cart-page").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    goToStep1() {
      if (this.activeTab == 1) {
        return;
      }
      if (this.activeTab == 2) {
        document.querySelector("#prev-btn-step-2").click();
      } else {
        document.querySelector("#prev-btn-step-3").click();
        this.$nextTick(() => {
          document.querySelector("#prev-btn-step-2").click();
        });
      }
    },
    goToStep2() {
      if (this.activeTab == 2) {
        return;
      }
      if (this.activeTab == 1) {
        document.querySelector("#next-btn-step-1").click();
      } else {
        document.querySelector("#prev-btn-step-3").click();
      }
    },
    goToStep3() {
      if (this.activeTab == 3) {
        return;
      }
      if (this.activeTab == 2) {
        document.querySelector("#next-btn-step-2").click();
      } else {
        document.querySelector("#next-btn-step-1").click();
        this.$nextTick(() => {
          document.querySelector("#next-btn-step-2").click();
        });
      }
    },
    onShippingsLoaded(shippings) {
      this.shippings = shippings;
    },
    onShippingSelected(shipping) {
      this.selectedShipping = shipping;
    },
    onGatewaysLoaded(gateways) {
      this.gateways = gateways;
    },
    onGatewaySelected(gateway) {
      this.selectedGateway = gateway;
    },
    onDiscountApplied(discount) {
      this.discount = discount;
    },
    onCouponApplied(coupon) {
      this.couponCode = coupon;
    },
    onPayMethodSelected(wallet) {
      this.payWallet = wallet;
    },
    onReserveSelected(reserved) {
      this.reserved = reserved;
    },
    setReservations(reservations) {
      this.reservations = reservations;
    },
    lastOrderCheck(num) {
      this.addItem = num;
    },
    async pay({ orderDescription, selectedGift }) {
      this.waitingForPayStatus = "loading";
      try {
        let data = {
          address_id: this.selectedAddress.id,
          shipping_id: this.selectedShipping.id,
          payment_driver: this.selectedGateway.name,
          reserved: this.reserved,
          coupon_code: this.couponCode,
          description: orderDescription ? orderDescription : "",
          add_item: this.addItem,
          order_gift_id: selectedGift ? selectedGift : null,
        };
        if (this.payWallet) {
          if (this.finalTotal > this.user.wallet.balance) {
            data.pay_wallet = true;
          } else {
            data.pay_wallet = true;
          }
        } else {
          data.pay_wallet = false;
        }
        const response = await this.$axios.post("customer/orders", data);

        this.waitingForPayStatus = "redirecting";
        let responseData = response.data.data;
        this.$root.notify(response.data.message, "success");
        if (responseData.need_pay) {
          let makeResponse = responseData.make_response;
          pay(makeResponse);
        } else {
          this.$router.push("/order/" + responseData.order_id);
        }
      } catch (error) {
        console.error(error);
        this.waitingForPayStatus = "error";
        this.$root.notify(error, "error");
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("front/setHasExtraWeight", false);
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("customer/order-gift-ranges").then((res) => {
        this.gifts = res.data.data.gifts;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.move-to-step {
  position: absolute;
  transition: 0.3s ease;
  right: 48px;
  @media screen and (max-width: 380px) {
    right: 30px;
  }
  button {
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid var(--color-theme);
    background: transparent !important;
    border-top: none;
    border-left: none;
    rotate: -45deg;
    transition: 0.5s ease;
    &:hover {
      scale: 1.1;
    }
  }
}

.cart-top2 {
  padding-top: 1rem !important;
}
</style>
