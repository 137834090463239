<template>
  <div>
    <main class="main product-details-main">
      <PagesPath
        v-if="blog != null"
        :pathTitle1="'پست ها'"
        :pathTo1="'/weblog-list'"
        :pathTitle2="blog != null ? blog.post.title : ''"
      ></PagesPath>
      <transition name="progress-fade">
        <div v-show="isShowProgressBar" class="progress-container">
          <div class="progress-bar" :style="{ width: progress }"></div>
        </div>
      </transition>
      <div class="weblog-list-grid container text-right mt-md-3 mt-3">
        <section
          v-if="helpList"
          class="weblog-categories box-shaddow10 p-3 bg-white mb-3 showInMobile"
        >
          <div class="d-flex justify-content-center border-bottom-dashed pb-2">
            <h6 class="mr-2 weight-bold text-color-theme fontsize-medium">
              آنچه در این مقاله میخوانید
            </h6>
          </div>
          <ul class="py-3">
            <li
              style="cursor: pointer"
              @click="gotoSection('weblogDetailSummary')"
            >
              مقدمه
            </li>
            <li
              class="fontsize-mini"
              style="cursor: pointer"
              @click="gotoSection(item.id)"
              v-for="(item, index) in helpList"
              :key="'item' + index"
            >
              {{ item.title }}
            </li>
          </ul>
        </section>
        <div class="weblog-list-main mb-md-5 mb-4">
          <section
            class="weblog-details bg-white px-3 box-shaddow20 py-4 mb-5"
            id="weblogMainContent"
          >
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <h1 v-else class="text-color-444 fontsize-heavy weight-bold mb-4">
              {{ blog.post.title }}
            </h1>
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <div
              v-else
              class="d-flex flex-wrap align-items-baseline mb-3 gap-20"
            >
              <!-- <div class="weblog-item-publisher d-flex align-items-baseline">
                <div class="article-user-img ml-1">
                  <img :src="blog.post.image.url" alt="">
                </div>
                <span class="text-color-666 fontsize-small">{{
                  blog.post.creatorable.name
                }}</span>
              </div> -->
              <div class="weblog-list-post-date">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.0356 5.7254C6.6969 5.7254 6.41602 5.44452 6.41602 5.10581V2.62746C6.41602 2.28875 6.6969 2.00787 7.0356 2.00787C7.37431 2.00787 7.65519 2.28875 7.65519 2.62746V5.10581C7.65519 5.44452 7.37431 5.7254 7.0356 5.7254Z"
                    fill="#474747"
                  />
                  <path
                    d="M13.643 5.7254C13.3043 5.7254 13.0234 5.44452 13.0234 5.10581V2.62746C13.0234 2.28875 13.3043 2.00787 13.643 2.00787C13.9817 2.00787 14.2626 2.28875 14.2626 2.62746V5.10581C14.2626 5.44452 13.9817 5.7254 13.643 5.7254Z"
                    fill="#474747"
                  />
                  <path
                    d="M7.44721 12.954C7.33982 12.954 7.23242 12.9293 7.13328 12.888C7.02589 12.8467 6.94329 12.7888 6.86067 12.7145C6.71197 12.5575 6.62109 12.3427 6.62109 12.1279C6.62109 11.9131 6.71197 11.6983 6.86067 11.5414C6.94329 11.467 7.03415 11.4092 7.13328 11.3679C7.33155 11.2853 7.56287 11.2853 7.76114 11.3679C7.86027 11.4092 7.95114 11.467 8.03375 11.5414C8.06679 11.5827 8.10809 11.624 8.13288 11.6653C8.16592 11.7149 8.19073 11.7644 8.20725 11.814C8.23203 11.8636 8.24855 11.9131 8.25681 11.9627C8.26508 12.0205 8.27333 12.0784 8.27333 12.1279C8.27333 12.3427 8.18245 12.5575 8.03375 12.7145C7.95114 12.7888 7.86027 12.8467 7.76114 12.888C7.66201 12.9293 7.55461 12.954 7.44721 12.954Z"
                    fill="#474747"
                  />
                  <path
                    d="M10.3398 12.9537C10.2324 12.9537 10.125 12.929 10.0259 12.8877C9.91847 12.8463 9.83586 12.7885 9.75325 12.7141C9.60455 12.5572 9.51367 12.3424 9.51367 12.1276C9.51367 12.078 9.52192 12.0202 9.53019 11.9624C9.53845 11.9128 9.55497 11.8633 9.57975 11.8137C9.59627 11.7641 9.62108 11.7146 9.65412 11.665C9.68717 11.6237 9.72021 11.5824 9.75325 11.5411C10.0589 11.2354 10.6124 11.2354 10.9263 11.5411C10.9594 11.5824 10.9924 11.6237 11.0255 11.665C11.0585 11.7146 11.0833 11.7641 11.0998 11.8137C11.1246 11.8633 11.1411 11.9128 11.1494 11.9624C11.1577 12.0202 11.1659 12.078 11.1659 12.1276C11.1659 12.3424 11.075 12.5572 10.9263 12.7141C10.7694 12.8628 10.5628 12.9537 10.3398 12.9537Z"
                    fill="#474747"
                  />
                  <path
                    d="M7.44721 15.8454C7.33982 15.8454 7.23242 15.8206 7.13328 15.7793C7.03415 15.738 6.94329 15.6802 6.86067 15.6058C6.71197 15.4489 6.62109 15.2341 6.62109 15.0193C6.62109 14.9697 6.62935 14.9119 6.63761 14.8623C6.64587 14.8045 6.66239 14.7549 6.68717 14.7054C6.70369 14.6558 6.7285 14.6062 6.76154 14.5567C6.78633 14.5153 6.82763 14.474 6.86067 14.4327C6.94329 14.3584 7.03415 14.3005 7.13328 14.2592C7.33155 14.1766 7.56287 14.1766 7.76114 14.2592C7.86027 14.3005 7.95114 14.3584 8.03375 14.4327C8.06679 14.474 8.10809 14.5153 8.13288 14.5567C8.16592 14.6062 8.19073 14.6558 8.20725 14.7054C8.23203 14.7549 8.24855 14.8045 8.25681 14.8623C8.26508 14.9119 8.27333 14.9697 8.27333 15.0193C8.27333 15.2341 8.18245 15.4489 8.03375 15.6058C7.95114 15.6802 7.86027 15.738 7.76114 15.7793C7.66201 15.8206 7.55461 15.8454 7.44721 15.8454Z"
                    fill="#474747"
                  />
                  <path
                    d="M17.3608 9.10389H3.31685C2.97815 9.10389 2.69727 8.82301 2.69727 8.4843C2.69727 8.1456 2.97815 7.86472 3.31685 7.86472H17.3608C17.6996 7.86472 17.9804 8.1456 17.9804 8.4843C17.9804 8.82301 17.6996 9.10389 17.3608 9.10389Z"
                    fill="#474747"
                  />
                  <path
                    d="M13.4945 19.7933C13.1806 19.7933 12.8832 19.6776 12.6684 19.4629C12.4123 19.2068 12.2966 18.835 12.3545 18.4467L12.5114 17.3315C12.5527 17.0423 12.7262 16.6954 12.9327 16.4888L15.8572 13.5644C16.2537 13.1678 16.642 12.9613 17.0633 12.92C17.5838 12.8704 18.0877 13.0852 18.5668 13.5644C19.0708 14.0683 19.7482 15.0927 18.5668 16.274L15.6424 19.1985C15.4359 19.405 15.0889 19.5785 14.7998 19.6198L13.6845 19.7768C13.6184 19.785 13.5606 19.7933 13.4945 19.7933ZM17.2038 14.1509C17.1955 14.1509 17.1872 14.1509 17.179 14.1509C17.0633 14.1592 16.9064 14.2666 16.7329 14.44L13.8084 17.3645C13.7836 17.3893 13.7423 17.4719 13.7423 17.505L13.5936 18.5376L14.6263 18.3889C14.6593 18.3806 14.7419 18.3393 14.7667 18.3145L17.6912 15.3901C18.0547 15.0266 18.1042 14.8449 17.6912 14.4318C17.559 14.3079 17.369 14.1509 17.2038 14.1509Z"
                    fill="#474747"
                  />
                  <path
                    d="M17.7093 16.8768C17.6515 16.8768 17.5936 16.8686 17.544 16.852C16.4536 16.5464 15.5862 15.679 15.2805 14.5885C15.1896 14.258 15.3796 13.9193 15.7101 13.8202C16.0405 13.7293 16.3792 13.9193 16.4784 14.2498C16.6684 14.9272 17.2053 15.4641 17.8828 15.6541C18.2132 15.745 18.4032 16.092 18.3123 16.4225C18.2297 16.6951 17.9819 16.8768 17.7093 16.8768Z"
                    fill="#474747"
                  />
                  <path
                    d="M10.3398 19.7691H7.03533C4.02 19.7691 2.28516 18.0343 2.28516 15.019V7.99697C2.28516 4.98164 4.02 3.2468 7.03533 3.2468H13.6443C16.6596 3.2468 18.3944 4.98164 18.3944 7.99697V10.8884C18.3944 11.2271 18.1136 11.508 17.7749 11.508C17.4361 11.508 17.1553 11.2271 17.1553 10.8884V7.99697C17.1553 5.63427 16.007 4.48597 13.6443 4.48597H7.03533C4.67264 4.48597 3.52433 5.63427 3.52433 7.99697V15.019C3.52433 17.3817 4.67264 18.53 7.03533 18.53H10.3398C10.6785 18.53 10.9594 18.8108 10.9594 19.1496C10.9594 19.4883 10.6785 19.7691 10.3398 19.7691Z"
                    fill="#474747"
                  />
                </svg>

                <span
                  class="haederBlogDetail fontsize-mini font-w-900 text-color-grey pr-1"
                  >تاریخ انتشار :</span
                >
                <span class="fontsize-mini text-color-666">{{
                  blog.post.published_at | Date
                }}</span>
              </div>
              <div class="fontsize-small weblog-list-post-date">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.416 9.58964H11.1304C9.41625 9.58964 8.54883 8.61895 8.54883 6.69823V4.72243C8.54883 4.02023 8.63144 3.01512 9.36118 2.46438C9.97388 2.01001 10.8551 1.97559 12.1562 2.35423C13.8635 2.8499 15.2886 4.27495 15.7842 5.98226C16.1629 7.27651 16.1285 8.16459 15.6741 8.7704C15.1233 9.50703 14.1182 9.58964 13.416 9.58964ZM10.6348 3.12527C10.3525 3.12527 10.1322 3.18034 9.98765 3.29049C9.71916 3.49014 9.58836 3.95827 9.58836 4.72243V6.70511C9.58836 8.2472 10.1804 8.56388 11.1373 8.56388H13.4229C14.1802 8.56388 14.6483 8.43307 14.8549 8.16459C15.0958 7.84791 15.0752 7.21455 14.8067 6.28517C14.4074 4.92896 13.237 3.75174 11.8808 3.35934C11.3714 3.201 10.9583 3.12527 10.6348 3.12527Z"
                    fill="#474747"
                  />
                  <path
                    d="M8.42666 16.4739C8.0618 16.4739 7.69004 16.4464 7.31829 16.3844C4.50261 15.9301 2.21013 13.6445 1.75577 10.8288C1.1706 7.20765 3.50438 3.79304 7.07734 3.0633C7.3596 3.00823 7.62808 3.18722 7.69004 3.46259C7.74512 3.74485 7.56613 4.01334 7.29075 4.07529C4.26854 4.69488 2.28586 7.58629 2.78841 10.6636C3.17394 13.0456 5.10843 14.98 7.4904 15.3656C10.5815 15.8612 13.466 13.8717 14.0787 10.8357C14.1338 10.5534 14.4091 10.3744 14.6845 10.4295C14.9668 10.4846 15.1458 10.76 15.0907 11.0353C14.4367 14.2503 11.6279 16.4739 8.42666 16.4739Z"
                    fill="#474747"
                  />
                </svg>

                <span class="fontsize-mini font-w-900 text-color-grey pl-1"
                  >بازدید :</span
                >
                <span class="haederBlogDetail fontsize-mini text-color-666">{{
                  blog.post.view_count
                }}</span>
              </div>
              <div class="fontsize-small weblog-list-post-date">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 16.74C5.79 16.74 5.57249 16.6876 5.37749 16.5826C4.94999 16.3576 4.6875 15.9075 4.6875 15.4275V14.3626C2.4225 14.1301 0.9375 12.465 0.9375 10.08V5.58008C0.9375 3.00008 2.67 1.26758 5.25 1.26758H12.75C15.33 1.26758 17.0625 3.00008 17.0625 5.58008V10.08C17.0625 12.66 15.33 14.3925 12.75 14.3925H9.92249L6.72748 16.5226C6.50998 16.6651 6.255 16.74 6 16.74ZM5.25 2.38507C3.315 2.38507 2.0625 3.63757 2.0625 5.57257V10.0726C2.0625 12.0076 3.315 13.2601 5.25 13.2601C5.5575 13.2601 5.8125 13.5151 5.8125 13.8226V15.4201C5.8125 15.5176 5.8725 15.5626 5.91 15.5851C5.9475 15.6076 6.02251 15.6301 6.10501 15.5776L9.44252 13.3576C9.53252 13.2976 9.64501 13.2601 9.75751 13.2601H12.7575C14.6925 13.2601 15.945 12.0076 15.945 10.0726V5.57257C15.945 3.63757 14.6925 2.38507 12.7575 2.38507H5.25Z"
                    fill="#474747"
                  />
                </svg>

                <span class="fontsize-mini font-w-900 text-color-grey mr-1"
                  >دیدگاه :</span
                >
                <span
                  v-if="blog.post.comments.length != 0"
                  class="fontsize-mini text-color-666"
                  >{{ blog.post.comments.length }}</span
                >
                <span v-else class="text-color-666 weight-bold pr-1">0</span>
              </div>

              <div
                v-if="blog.post.read_time"
                class="fontsize-small weblog-list-post-date"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  stroke="#474747"
                  fill="#474747"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M464 256a208 208 0 1 1-416 0a208 208 0 1 1 416 0M0 256a256 256 0 1 0 512 0a256 256 0 1 0-512 0m232-136v136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24"
                  />
                </svg>
                <span class="fontsize-mini font-w-900 text-color-grey mr-1"
                  >زمان مطالعه :</span
                >
                <span class="fontsize-mini text-color-666">{{
                  blog.post.read_time
                }}</span>
                <span> دقیقه </span>
              </div>
            </div>
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
            <template v-else>
              <img
                v-if="blog.post.image != null"
                :src="blog.post.image.url"
                :alt="blog.post.title"
                class="articleImage"
              />
            </template>
            <br v-if="blogStatus" />
            <!-- <h5
              id="weblogDetailSummary"
              v-else
              class="text-color-444 mt-5 mb-4"
            >
              {{ blog.post.summary }}
            </h5> -->
            <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
            <div
              id="blogDetailBox"
              class="mt-5"
              v-else
              v-html="blog.post.body"
            ></div>
            <!-- <hr />
            <div class="d-flex justify-content-between">
              <span class="fontsize14 weight-bold ml-3 flex-shrink-0">برچسب ها:</span>
              <div  class="fontsize12 text-color-999">
                <span>#لباس</span>
                <span>#کفش</span>
                <span>#لباس-گرم</span>
                <span>#لباس-تابستانی</span>
                <span>#لباس</span>
                <span>#کفش</span>
                <span>#لباس-گرم</span>
                <span>#لباس-تابستانی</span>
              </div>
            </div> -->
            <hr />
            <b-skeleton v-if="blogStatus"></b-skeleton>
            <div v-else class="d-flex justify-content-between">
              <span class="fontsize-small weight-bold ml-3"
                >اشتراک گذاری مطلب :</span
              >
              <section class="sotial-networks">
                <a
                  :href="
                    'whatsapp://send?text=' + encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
                <!-- <a href="" class="mx-2">
                  <svg width="15" height="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">

                            <path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                h192c61.76,0,112,50.24,112,112V352z" fill="#bbb"/>
                    <path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z" fill="#bbb"/>
                    <circle cx="393.6" cy="118.4" r="17.056" fill="#bbb"/>
                    </svg>
                </a> -->
                <a
                  :href="
                    'http://twitter.com/share?url=' +
                    encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                C480.224,136.96,497.728,118.496,512,97.248z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
                <!-- <a href="" class="mx-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                    <path fill="#bbb" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                  </svg>
                </a> -->
                <a
                  :href="
                    'https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURIComponent(getBlogLink())
                  "
                  class="mx-2"
                >
                  <svg
                    id="Capa_1"
                    height="18"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="18"
                    x="0px"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                  >
                    <path
                      d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
                                h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"
                      fill="#bbb"
                    />
                  </svg>
                </a>
              </section>
            </div>
          </section>
          <b-skeleton v-if="blogStatus"></b-skeleton>
          <section
            v-else-if="blog && blog.suggests != ''"
            class="similar-articles hideOnMobile"
          >
            <div
              class="d-flex justify-content-between align-items-baseline mb-3"
            >
              <h5 class="fontsize-heavy">مطالب مشابه</h5>
              <router-link
                class="recommended-products-more fontsize-small text-white bg-color-a5 px-3 py-2 mt-3 d-inline-block"
                to="/weblog-list"
                >مشاهده همه پست ها
              </router-link>
            </div>

            <section class="weblog-list-main-grid">
              <!-- افزودن پست های پیشنهادی با ارسال داده به صورت پراپس -->
              <Weblog
                v-for="(suggest, index) in blog.suggests"
                :key="'suggest' + index"
                :weblog="suggest"
              ></Weblog>
            </section>
          </section>
          <CommentSection class="hideOnMobile" />
        </div>
        <div class="welog-list-side">
          <section
            id="sugesstedWeblog"
            v-if="blog && blog.suggests != ''"
            class="similar-articles showInMobile"
          >
            <div
              class="d-flex justify-content-between align-items-baseline mb-3"
            >
              <h5 class="fontsize-heavy">مطالب مشابه</h5>
              <router-link
                class="recommended-products-more fontsize-small text-white bg-color-a5 px-3 py-2 mt-3 d-inline-block"
                to="/weblog-list"
                >مشاهده همه پست ها
              </router-link>
            </div>

            <section class="weblog-list-main-grid">
              <!-- افزودن پست های پیشنهادی با ارسال داده به صورت پراپس -->
              <Weblog
                v-for="(suggest, index) in blog.suggests"
                :key="'suggest' + index"
                :weblog="suggest"
              ></Weblog>
            </section>
          </section>
          <CommentSection id="commentSection" class="showInMobile" />
          <section
            v-if="helpList"
            id="stickyHelpList"
            class="weblog-categories box-shaddow20 p-3 bg-white mb-3 hideIn768"
          >
            <div
              class="d-flex justify-content-center border-bottom-dashed pb-2"
            >
              <h6 class="mr-2 weight-bold text-color-theme fontsize-medium">
                آنچه در این مقاله میخوانید
              </h6>
            </div>
            <ul class="py-3">
              <li
                class="fontsize-mini"
                style="cursor: pointer"
                @click="gotoSection('weblogDetailSummary')"
              >
                مقدمه
              </li>
              <li
                class="fontsize-mini"
                style="cursor: pointer"
                @click="gotoSection(item.id)"
                v-for="(item, index) in helpList"
                :key="'item' + index"
              >
                {{ item.title }}
              </li>
            </ul>
          </section>
          <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
          <br v-if="blogStatus" />
          <section
            id="categoryOption"
            v-else
            class="weblog-categories box-shaddow20 p-3 bg-white mb-3"
          >
            <div
              class="d-flex justify-content-center border-bottom-dashed pb-2"
            >
              <h6 class="mr-2 weight-bold text-color-theme fontsize-medium">
                دسته بندی ها
              </h6>
            </div>
            <ul class="py-3">
              <li
                v-for="(category, index) in blog.category"
                :key="'category' + index"
              >
                <router-link
                  class="fontsize-small"
                  :to="{
                    name: 'weblogList',
                    params: { id: category.id, slug: category.slug },
                  }"
                >
                  {{ category.name }}
                </router-link>
              </li>
            </ul>
          </section>
          <div id="weblogBanner" class="mb-4">
            <HomeBanners :banner="advertiseBanners.website_ads" class="w-100" />
            <!-- <img class="w-100" src="../../../assets/images/advertise.png" alt="" /> -->
          </div>
          <b-skeleton-img v-if="blogStatus"></b-skeleton-img>
          <section
            class="d-flex flex-column latestWeblogs-container box-shaddow20"
            id="latestWeblogsContainer"
            v-else-if="blog && blog.lastPost != ''"
          >
            <article
              v-for="(post, index) in blog.lastPost"
              :key="'post' + index"
              id="latestWeblogs"
            >
              <router-link
                :to="{
                  name: 'blogDetail',
                  params: { id: post.id, slug: post.slug },
                }"
              >
                <div class="latestWeblogs-img">
                  <img v-if="post.image" :src="post.image.url" alt="" />
                  <img
                    v-else
                    src="../../../assets/images/blog.jpg"
                    alt="blog"
                  />
                  <div class="latestWeblogs-info">
                    <div class="d-flex align-items-center gap-5">
                      <svg
                        data-v-9485f4d0=""
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          data-v-9485f4d0=""
                          d="M14.6667 20.8541H7.33333C3.9875 20.8541 2.0625 18.9291 2.0625 15.5833V6.41658C2.0625 3.07075 3.9875 1.14575 7.33333 1.14575H14.6667C18.0125 1.14575 19.9375 3.07075 19.9375 6.41658V15.5833C19.9375 18.9291 18.0125 20.8541 14.6667 20.8541ZM7.33333 2.52075C4.71167 2.52075 3.4375 3.79492 3.4375 6.41658V15.5833C3.4375 18.2049 4.71167 19.4791 7.33333 19.4791H14.6667C17.2883 19.4791 18.5625 18.2049 18.5625 15.5833V6.41658C18.5625 3.79492 17.2883 2.52075 14.6667 2.52075H7.33333Z"
                          fill="#ffffff"
                        ></path>
                        <path
                          data-v-9485f4d0=""
                          d="M16.9584 8.47917H15.1251C13.7317 8.47917 12.6042 7.35167 12.6042 5.95833V4.125C12.6042 3.74917 12.9159 3.4375 13.2917 3.4375C13.6676 3.4375 13.9792 3.74917 13.9792 4.125V5.95833C13.9792 6.59083 14.4926 7.10417 15.1251 7.10417H16.9584C17.3342 7.10417 17.6459 7.41583 17.6459 7.79167C17.6459 8.1675 17.3342 8.47917 16.9584 8.47917Z"
                          fill="#ffffff"
                        ></path>
                        <path
                          data-v-9485f4d0=""
                          d="M10.9999 12.6042H7.33325C6.95742 12.6042 6.64575 12.2926 6.64575 11.9167C6.64575 11.5409 6.95742 11.2292 7.33325 11.2292H10.9999C11.3758 11.2292 11.6874 11.5409 11.6874 11.9167C11.6874 12.2926 11.3758 12.6042 10.9999 12.6042Z"
                          fill="#ffffff"
                        ></path>
                        <path
                          data-v-9485f4d0=""
                          d="M14.6666 16.2708H7.33325C6.95742 16.2708 6.64575 15.9591 6.64575 15.5833C6.64575 15.2074 6.95742 14.8958 7.33325 14.8958H14.6666C15.0424 14.8958 15.3541 15.2074 15.3541 15.5833C15.3541 15.9591 15.0424 16.2708 14.6666 16.2708Z"
                          fill="#ffffff"
                        ></path>
                      </svg>
                      <h6 class="fontsize13 m-0">
                        {{ post.title.substr(0, 30) }}
                        {{ post.title.length > 30 ? "..." : "" }}
                      </h6>
                    </div>
                  </div>
                </div>
              </router-link>
            </article>
          </section>
          <section
            class="mt-5x mb-3"
            id="swiperBlog"
            v-if="blog && blog.post.products && blog.post.products.length"
          >
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <Product
                  class="swiper-slide"
                  v-for="(product, index) in blog.post.products"
                  :key="index + 'index'"
                  :product="product"
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
      <FooterButtons></FooterButtons>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import PagesPath from "@/parts/Front/components/PagesPath";
import Weblog from "@/parts/Front/components/Weblog";
import CommentSection from "@/parts/Front/components/weblog/CommentSection";
import { BSkeleton, BSkeletonImg } from "bootstrap-vue";
import Swiper from "swiper/swiper-bundle.min";
import HomeBanners from "@/parts/Front/components/HomeBanners.vue";
import Product from "@/parts/Front/components/product/Product";
export default {
  name: "WblogDetails",
  components: {
    CommentSection,
    ScrollToTop,
    FooterButtons,
    PagesPath,
    Weblog,
    BSkeleton,
    BSkeletonImg,
    HomeBanners,
    Product,
  },
  beforeCreate() {
    this.$store.dispatch(
      "front/getBlogDetailFromServer",
      this.$route.params.id
    );
  },
  watch: {
    blog() {
      this.$nextTick(() => {
        let h2s = document.querySelectorAll("h2");
        let list = [];
        h2s.forEach((h2, index) => {
          h2.id = `title${index + 1}`;
          list.push({
            title: h2.innerText,
            id: `title${index + 1}`,
          });
        });
        this.helpList = list;
        this.initSlider();
      });
    },
  },
  computed: {
    blog() {
      return this.$store.getters["front/getBlogDetail"];
    },
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
    targetElement() {
      const targetElement = document
        .getElementById("latestWeblogsContainer")
        .getBoundingClientRect();
      return targetElement.top + targetElement.height;
    },
    advertiseBanners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].advertise;
      } else {
        return {};
      }
    },
  },
  data() {
    return {
      helpList: [],
      sideEndIsReached: false,
      progress: "0%",
      isShowProgressBar: false,
    };
  },
  methods: {
    initSlider(kill = false) {
      this.initSwiper("swiperBlog", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#swiperBlog .swiper-container", {
          spaceBetween: 1,
          loop: false,
          speed: 500,
          centeredSlides: true,
          centeredSlidesBounds: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          slidesPerView: 1,
          spaceBetween: 1,
          navigation: {
            nextEl: ".recommended-products .next-btn-holder",
            prevEl: ".recommended-products .prev-btn-holder",
          },
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(".recommended-products .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    gotoSection(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getBlogLink() {
      // گرفتن لینک این بلاگ برای اشتراک گزاری
      if (!this.blog.post) {
        return "";
      }
      return (
        window.location.hostname +
        "/blogs/" +
        this.blog.post.id +
        "/" +
        this.blog.post.slug
      );
    },
    isHelpListShow() {
      if (scrollY > this.targetElement) {
        this.sideEndIsReached = true;
      } else {
        this.sideEndIsReached = false;
      }
    },
    updateScrollIndicator() {
      let blogDetailBox = document.getElementById("blogDetailBox");
      let sy = window.scrollY - 150;
      if (sy > 0 && blogDetailBox.clientHeight > sy) {
        this.progress = (sy * 100) / blogDetailBox.clientHeight + "%";
      } else if (sy > 0 && blogDetailBox.clientHeight < sy) {
        this.progress = "100%";
      } else {
        this.progress = "0%";
      }
    },
    progressBarFlag() {
      this.isShowProgressBar = scrollY > 150 ? true : false;
    },
    handleScroll() {
      this.isHelpListShow();
      this.updateScrollIndicator();
      this.progressBarFlag();
    },
    // تابع زیر برای اضافه کردن متا تگ و بالا بردن سئو است
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // در صورتی که کابر لاگین کرده بود و ایمیل داشت مقدار پیشفرض ایمیل را همان مفدار نشان بده
    if (this.$store.getters["front/getHomeData"] != null) {
      if (this.$store.getters["front/getHomeData"].user.user != false) {
        if (this.$store.getters["front/getHomeData"].user.user.email != null) {
          this.email = this.$store.getters["front/getHomeData"].user.user.email;
        }
      }
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.blog ? this.blog.post.meta_description : false,
        },
        {
          property: "og:title",
          content: this.blog ? this.blog.post.title : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#weblogMainContent * {
  font-family: "font-iran-sans" !important;
}

.inputAlert {
  background-color: rgb(255 250 244);
}
/* افزودن استایل به کدهای اضافی قالب */
.articleImage {
  width: 100%;
  object-fit: fill;
}
.haederBlogDetail {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.weblog-details >>> img {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  .side-end-is-reached {
    position: sticky;
    z-index: 1030;
    animation-name: stickyBox;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  @keyframes stickyBox {
    0% {
      top: -100px;
    }
    100% {
      top: 110px;
    }
  }
}
@media screen and (max-width: 550px) {
  .weblog-details >>> img {
    height: auto !important;
  }
}
.font-size-26 {
  font-size: 26px;
}
.latestWeblogs-container {
  border-radius: 20px;
  overflow: hidden;
}
#latestWeblogs {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  transition: all 1s ease-in-out;
  height: 100px;
}
#latestWeblogs a {
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
#latestWeblogs .latestWeblogs-img {
  position: relative;
  height: 100%;
  object-fit: cover;
}

#latestWeblogs .latestWeblogs-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000b5;
}

#latestWeblogs .latestWeblogs-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#latestWeblogs .latestWeblogs-info {
  position: absolute;
  top: 50%;
  right: 10px;
  /* transform: translateY(50%); */
}
#latestWeblogs .latestWeblogs-info h6 {
  color: #ffffff;
}
#latestWeblogs:hover .latestWeblogs-info h6 {
  color: #000000;
}
#latestWeblogs:hover .latestWeblogs-info svg path {
  fill: #000000;
}
#latestWeblogs:hover .latestWeblogs-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #97979769;
}
.weblog-categories ul li:hover,
.weblog-categories ul li a:hover {
  color: var(--color-theme);
}
.progress-container {
  position: fixed;
  top: 50%;
  z-index: 1040;
  width: 200px;
  height: 12px;
  background: #ccc;
  transform: rotate(-90deg);
  border-radius: 5px;
}

.progress-bar {
  height: 12px;
  background: rgb(241, 114, 172);
  width: 0%;
  border-radius: 5px;
}
.progress-fade-enter-active {
  transition: 700ms;
}
.progress-fade-leave-active {
  transition: all 200ms cubic-bezier(1, 0.5, 0.8, 1);
}
.progress-fade-enter,
.progress-fade-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
section#swiperBlog {
  width: 300px;
  max-width: 100%;
  margin: auto;
  position: sticky;
  top: 150px;
  margin-top: 2rem;
}
@media (max-width: 580px) {
  section#swiperBlog {
    position: unset;
  }
}
</style>
<style>
iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: 100%;
}
#blogDetailBox p {
  margin: 20px !important;
  line-height: 35px !important;
}
#blogDetailBox p * {
  font-size: 16px !important;

}
#blogDetailBox h1 {
  margin: 22px;
}
#blogDetailBox h1 * {
  font-size: 22px !important;
}
#blogDetailBox h2 * {
  font-size: 19px !important;
}
#blogDetailBox h3 * {
  font-size: 18px !important;
}
#blogDetailBox h4 * {
  font-size: 17px !important;
}

@media (max-width: 768px) {
  .progress-container {
    height: 8px !important;
    right: -20%;
  }
  .progress-bar {
    height: 8px !important;
  }
  .hideOnMobile {
    display: none;
  }
  .showInMobileGrid {
    display: grid;
  }
  .welog-list-side {
    display: flex;
    flex-direction: column;
  }
  #section#swiperBlog {
    order: 1;
  }
  #section#swiperBlog {
    order: 1;
  }
  .weblog-comments-section {
    order: 2;
  }
  #weblogBanner {
    order: 3;
  }
  #sugesstedWeblog {
    order: 4;
  }
  #categoryOption {
    order: 5;
  }
  #latestWeblogsContainer {
    order: 6;
  }
}
</style>
