<script setup>
import {ref} from "vue";

defineProps(['data','index','length'])
const questions = ref(null)
</script>

<template>
  <div v-if="data" class="form-group " :id="`item-${data.id}`">
      <div>
        <div class="d-flex align-items-center mb-2">
          <span class="ml-1">{{ index + 1 }} -</span>
          <h5 class="text-sm mb-0">{{ data.body }}</h5>
        </div>
        <div>
          <ul class="row">
            <li v-for="answer in data?.options" :key="answer.id" class="d-flex col-12 col-lg-6 mt-2 align-items-baseline">
              <input class="check-box" hidden :value="{question_id:data.id , answer_id:answer.id}" type="radio"
                     :name="`question-${data.id}`" :id="`question-${data.id}-answer-${answer.id}`"
                     v-model="questions">
              <label class="mr-1" :for="`question-${data.id}-answer-${answer.id}`">
                <span class="checked"></span>
                <span> {{ answer.body }}</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    <div class="d-flex flex-row-reverse justify-content-between align-items-center button-group">
      <button class="btn btn-sm shadow-sm rounded  " @click="$emit('sendData',false ,'-')" v-show="index+1 > 1">سوال قبل</button>
      <button class="btn btn-sm shadow-sm rounded ml-auto" v-show="index+1!=length"  @click="$emit('sendData',questions ,'+')" :disabled="!questions">سوال بعدی</button>
      <button class="btn btn-sm shadow-sm rounded ml-auto" v-show="index+1==length"  @click="$emit('sendData',questions ,'')" :disabled="!questions">ارسال</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.form-group {
  background: #fff;
  z-index: 5;
  margin: auto;
  h5 {
    font-weight: bold ;
    font-size: 18px !important;
  }
}

//start buttons
  .button-group{
    button:first-child{
      width: fit-content;
      background: white;
      border:1px solid var(--color-themeGreen3);
      color:var(--color-themeGreen3) !important;
      border-radius:5px ;
      transition:all 0.3s ease ;
      &:hover{
        background:var(--color-themeGreen3) ;
        color: white !important;
      }
    }
    button:nth-child(2){
      width: fit-content;
      background: white;
      border:1px solid var(--color-theme);
      color:var(--color-theme)!important;
      border-radius:5px ;
      transition:all 0.3s ease ;
      &:hover{
        background:var(--color-theme);
        color: white !important;
      }
    }
    button:last-child{
      width: fit-content;
      background: white;
      border:1px solid var(--color-theme);
      color:var(--color-theme) !important;
      border-radius:5px ;
      transition:all 0.3s ease ;
      &:hover{
        background:var(--color-theme);
        color: white !important;
      }
    }
    button:hover{
      color:#fff !important;
    }
  }
//end buttons

/*create checkbox*/


label {
cursor: pointer;
display: flex;
align-items: baseline;
gap: 4px;
}

.checked {
position: relative;
cursor: pointer;
bottom: -2px;
display: flex;
align-items: center;
justify-content: center;
width: 16px;
height: 16px;
background: var(--color-f0);
border-radius: 3px;
border: 1px solid #c7c7c7;
transition: 0.2s ease;

&:after {
content: "";
position: absolute;
width: 4px;
height: 9px;
bottom: 3.5px;
border: solid white 2px;
border-left: none;
border-top: none;
rotate: 40deg;
display: none;
}
}

input[type=radio]:checked + label > .checked {
background: #0075FF;

&:after {
display: block;
}
}

@media screen and (max-width: 768px) {
  .form-group {
    width: 100%;
  }
  .button-group{
    flex-direction: column-reverse;

  }
  button{
    //width: 100%;
    text-align: center;
    margin-top: 12px;
    display: block;
  }
}

/*create checkbox*/
</style>