var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.categories && _vm.categories.length)?_c('section',{attrs:{"id":"AllCategoriesSection"}},[_c('h1',{staticClass:"fontsize-regular font-w-800 text-color-grey text-center pt-5 px-2"},[_vm._v(" دسته بندی محصولات بندیتو ")]),_c('div',{staticClass:"container"},_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"pt-5 pb-5",class:category.children && category.children.length
          ? 'w-100'
          : 'single-categories'},[_c('h2',{staticClass:"fontsize-regular font-w-800 text-color-grey text-center mb-5"},[_vm._v(" "+_vm._s(category.title)+" ")]),(category.children && category.children.length)?_c('div',{staticClass:"w-100 d-flex align-items-center border-r-45 categories-children-container",class:{
          'swiper-container': _vm.mediaQueries.mobileSize,
          'w-max': category.children.length < 3,
        }},[_c('div',{staticClass:"w-100 d-flex align-items-center",class:{ 'swiper-wrapper': _vm.mediaQueries.mobileSize }},_vm._l((category.children),function(childCategory,index){return _c('router-link',{key:index,staticClass:"d-flex align-items-center flex-column categories-children swiper-slide position-relative",class:category.children && category.children.length
                ? 'width-25'
                : '' + ' ' + _vm.mediaQueries.mobileSize
                ? 'swiper-slide'
                : '',attrs:{"to":{
              name: 'product.category',
              params: {
                category: childCategory.id,
                slug: childCategory.slug,
              },
              query: _vm.query,
            }}},[(childCategory.image && childCategory.image.length)?_c('img',{staticClass:"w-100 h-100",attrs:{"src":_vm.imageResolver(childCategory),"alt":childCategory.title}}):_vm._e(),_c('h3',{staticClass:"fontsize-small font-w-800 text-color-grey position-absolute categories-title"},[_vm._v(" "+_vm._s(childCategory.title)+" ")])])}),1)]):_c('div',{staticClass:"border-r-45 categories-children-container"},[_c('router-link',{staticClass:"d-flex align-items-center flex-column categories-children position-relative",class:category.children && category.children.length ? '' : 'w-100',attrs:{"to":{
            name: 'product.category',
            params: { category: category.id, slug: category.slug },
            query: _vm.query,
          }}},[(category.image && category.image.url)?_c('img',{staticClass:"w-100 h-100",staticStyle:{"border-radius":"45px !important"},attrs:{"src":category.image.url,"alt":category.title}}):_vm._e(),_c('h3',{staticClass:"fontsize-small font-w-800 text-color-grey position-absolute bg-none categories-title"},[_vm._v(" "+_vm._s(category.title)+" ")])])],1)])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }