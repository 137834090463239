<template>
  <section
    v-if="tab === 5"
    class="user-information p-3 bg-white box-shaddow20 position-relative overflow-auto"
  >
    <h4 class="text-center">کیف پول</h4>
    <LineGrow open-width="90%" />
    <button
      data-toggle="modal"
      data-target="#depositModal"
      class="walletOptionBtn"
    >
      افزودن اعتبار
    </button>
    <b-skeleton-table
      v-if="status"
      :rows="5"
      :columns="4"
      :table-props="{ bordered: false, striped: false }"
    ></b-skeleton-table>
    <div v-else id="walletTable">
      <table class="table">
        <template v-if="transactions.data.length != 0">
          <thead>
            <tr id="trHeadWalletTabel" class="text-color-444 fontsize-small">
              <th class="hideIn768" scope="col">شناسه</th>
              <th scope="col">نوع تراکنش</th>
              <th scope="col">تاریخ تراکنش</th>
              <th scope="col">مبلغ(تومان)</th>
              <th scope="col">وضعیت</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in transactions.data"
              class="trHover"
              :key="transaction.id"
            >
              <th scope="row">
                {{ transaction.id }}
              </th>
              <td scope="row">
                <span
                  class="badge text-white"
                  :class="{
                    'badge-primary': transaction.type == 'deposit',
                    'badge-danger': transaction.type != 'deposit',
                  }"
                >
                  {{ transaction.type == "deposit" ? "واریزی  " : "برداشت " }}
                </span>
              </td>
              <td style="word-spacing: 3px">
                {{ moment(transaction.created_at).format("HH:mm YYYY/MM/DD") }}
              </td>
              <td>
                {{ transaction.amount.replace("-", "") | price }}
              </td>
              <td>
                <span
                  class="badge"
                  :class="{
                    'badge-success': transaction.confirmed == true,
                    'badge-danger': transaction.confirmed == false,
                  }"
                >
                  {{ transaction.confirmed == true ? "موفق" : "ناموفق" }}
                </span>
              </td>
              <td>
                {{
                  transaction.type == "deposit"
                    ? " "
                    : transaction.meta.description
                }}
              </td>
            </tr>
          </tbody>
        </template>
        <div class="notContent" v-else>
          <h4>اطلاعاتی برای نمایش وجود ندارد</h4>
        </div>
      </table>
    </div>
    <section
      id="mainPaginationBlog"
      v-if="transactions && transactions.last_page > 1"
      class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
    >
      <section
        v-if="transactions"
        id="listPagination"
        class="list-pagination weblog-list-pagination text-center my-4 py-2"
      >
        <pagination
          :limit="1"
          :data="transactions"
          @pagination-change-page="changePage"
          :disabled="status"
          class="justify-content-center"
        >
          <span slot="prev-nav">
            <ZPrevSvg />
          </span>
          <span slot="next-nav">
            <ZNextSvg />
          </span>
        </pagination>
      </section>
      <!-- <pagination :data="blogs.posts.links" :router="true" @pagination-change-page="changePage"></pagination> -->
    </section>
    <Deposit />
  </section>
</template>
<script>
import paginationMixin from "@/libraries/mixins/paginationMixin";
import Deposit from "@/parts/Front/components/panel/Wallet/DepositWallet.vue";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import { BSkeletonTable } from "bootstrap-vue";

export default {
  mixins: [paginationMixin],
  props: { tab: { Number } },
  components: {
    LineGrow,
    ZNextSvg,
    ZPrevSvg,
    Deposit,
    BSkeletonTable,
  },
  computed: {
    transactions() {
      return this.$store.getters["front/getTransaction"];
    },
    status() {
      return this.$store.getters["front/getTransactionStatus"];
    },
  },
  created() {
    if (this.transactions == null) {
      this.$store.dispatch(
        "front/getTransactionFromServer",
        this.$route.query.page != undefined ? this.$route.query.page : "1"
      );
    }
  },
  methods: {},
  watch: {
    "$route.query.page": {
      handler: function (val) {
        let page = val;
        if (this.allow)
          this.$store.dispatch("front/getTransactionFromServer", page);
      },
      immediate: true,
    },
  },
  data() {
    return {
      allow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.allow = true;
    }, 5000);
  },
};
</script>
<style scoped>
#headWalletTabel {
  border-top: solid transparent;
}
.walletOptionBtn {
  margin-bottom: 5px;
  background-color: #35a80b;
  color: white;
  border-radius: 7px;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  right: 30px;
}
#walletTable {
  width: 100%;
  overflow: auto;
}
#trHeadWalletTabel {
  border-top: solid transparent;
}
</style>
