<template>
  <section class="overflow-hidden  ">
    <div class=" d-flex justify-content-center">
      <h3 class="text-center">مسابقه ازمون </h3>
    </div>
    <div v-if="inviteLinkForCustomer?.length">
      <p id="invite-link" @click="copyInviteLink">{{inviteLinkForCustomer}}</p>
    </div>
    <div>
      <button @click="showGame = true" class="btn btn-outline-primary" v-if="!showGame">شروع مسابقه</button>
    </div>
    <div class="p-3 z-index-1 question-backdrop " :class="{'open':showGame,'close':!showGame}"
         @click.self="showGame = false" v-if="showGame">
      <div class="question-modal-content">
        <div>
          <form @submit.prevent="">
            <question-box @sendData="activeComponent" @submitForm="console.log" v-show="active === (index+1)"
                          v-for="(item , index) in exams?.exam?.questions" :key="item.id" :data="item" :index="index"
                          :length="exams?.exam?.questions.length"/>
          </form>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <span class="mx-2">{{ active }}</span>
          از
          <span class="mx-2">{{ exams?.exam?.questions?.length }}</span>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->
    <button type="button" id="showModal" class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModal">
      Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul v-if="examReward&&examReward?.gamificationResponse?.gameRewardObjects">
              <li v-for="item in examReward?.gamificationResponse?.gameRewardObjects" :key="item.id">
                <span>{{ handleRewardType(item.reward_type) }}</span>
                <span>{{ Number(item?.reward_value).toLocaleString() }}</span>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <bonus-loader v-if="loading"/>
  </section>
</template>

<script>
'use strict'
import axios from "axios";
import QuestionBox from "@/parts/Front/components/panel/exam/QuestionBox.vue";
import BonusLoader from "@/parts/Front/components/panel/exam/bonusLoader.vue";

export default {
  components: {BonusLoader, QuestionBox},
  data() {
    return {
      data: null,
      examReward: null,
      questions: [],
      examLength: null,
      active: 1,
      showGame: false,
      loading: false,
      inviteLinkForCustomer: "",
    }
  },
  methods: {
    activeComponent(event, op) {
      if (this.active <= this.examLength) {
        if (op == '+') {
          this.active += 1;
        }
      }
      if (this.active > 1) {
        if (op == '-') {
          this.active -= 1;
        }
      }
      if (event) {
        if (!this.questions.filter(item => item.question_id == event.question_id)?.length) {
          this.questions.push(event);
        }
      }
      if (this.active == this.examLength && this.questions.length == this.examLength) {
        this.fetchData()
      }
    },
    async fetchData() {
      //   const objIds = Object.values(this.questions).map(item => (item.question_id));
      //   const missingItems = questions.filter(item => {
      //     if (!objIds.includes(item.id)) {
      //       return item.id
      //     }
      //   });
      let formData = new FormData();
      this.questions.forEach((item, index) => {
        formData.append(`answers[${index}][question_id]`, item.question_id)
        formData.append(`answers[${index}][selected_option_id]`, item.answer_id)
      })
      try {
        this.loading = true
        this.showGame = false
        this.active = 1
        await axios.post(`/customer/games/exam`, formData)
            .then(response => {
              this.examReward = response.data.data
              this.inviteLinkForCustomer = response.data.data.inviteLinkForCustomer
              document.querySelector('button#showModal').click()
              // this.showMessage(response.data.data, true)
            })
            .catch(error => {
              this.showMessage(error.response.data.message, false)
            });
      } catch (e) {
      } finally {
        this.loading = false
      }

    },
    showMessage(msg, mode) {
      window
          .swal({
            title: msg,
            icon: mode ? 'success' : 'error',
            showConfirmButton: true,
            dangerMode: true,
            confirmButtonText: "باشه"
          })
    },
    handleRewardType(rewardType) {
      if (rewardType == 'nothing') {
        return 'پوچ'
      } else if (rewardType == 'gift_wallet') {
        return 'هدیه ی کیف پول'
      } else if (rewardType == 'coupon_percentage') {
        return 'کد تخفیف '
      } else if (rewardType == 'coupon_flat') {
        return 'کد تخفیف '
      } else if (rewardType == 'score') {
        return 'امتیاز مشتریان '
      }else if (rewardType == 'bon') {
        return 'بن باشگاه مشتریان '
      }
    },
    copyInviteLink() {
      const element = document.getElementById('invite-link');
      element.addEventListener('click', () => {
        const text = element.innerText; // متن داخل عنصر
        navigator.clipboard.writeText(text) // کپی متن به کلیپ بورد);
      });
    }
  },
  mounted() {

  },
  computed: {
    exams() {
      this.examLength = this.$store.getters["front/getExamGame"]?.exam?.questions?.length
      return this.$store.getters["front/getExamGame"];
    },
    loading() {
      return this.$store.getters["front/getExamGameStatus"];
    },
  },
  created() {
    this.$store.dispatch("front/getExamGame");
  },

}
</script>
<style scoped lang="scss">
section{
  height: 100%;
  background: white;
}
.question-backdrop {
  background: white;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  &.close {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;

  }

  .question-modal-content {
    height: fit-content;
    padding: 20px;
    width: 100%;
  }
}

</style>

