<template>
  <section class="cart-section px-2 py-4 mb-5 position-relative">
    <div class="container">
      <div class="row position-relative">
        <div class="col-12 col-lg-8">
          <div class="free-shipping-container d-lg-none">
            <freeShippingProgressBar :totalPrice="totalPrice" />
          </div>
          <div class="cart-table">
            <div
              class="cart-table-head cart-table-grid text-center border-bottom py-2 px-3"
            >
              <h6 class="text-right weight-bold text-color-444">
                سبد خرید شما
              </h6>
              <h6 class="text-color-444">قیمت واحد</h6>
              <h6 class="text-color-444">وزن واحد</h6>
              <h6 class="text-color-444">تعداد</h6>
              <h6 class="text-color-444">حذف</h6>
            </div>
            <template
              v-if="
                cart != null && cart.cartItems != null && cart.cartItems != ''
              "
            >
              <CartItem
                :defualtWeight="defualtWeight"
                @quantityChanged="recalculateCoupon"
                @init="init"
                v-for="(cartItem, index) in cart.cartItems"
                :key="index"
                :class="{ 'border-bottom': index != cart.cartItems.length - 1 }"
                v-model="cart.cartItems[index]"
                :index="index"
                :warning="
                  warning != ''
                    ? warning.filter((item) => item.cart_id == cartItem.id)[0]
                    : null
                "
                :deletedCartIds="
                  deletedCartIds != ''
                    ? deletedCartIds.filter((item) => item == cartItem.id)[0]
                    : null
                "
              />
            </template>
            <template v-else>
              <div class="text-center my-5">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                    fill="#999"
                  />
                </svg>
                <h5 class="text-color-999 pt-3 fontsize-large">
                  سبد خرید شما خالی می باشد.
                </h5>
              </div>
            </template>
          </div>
        </div>
        <section class="col-12 discount-code-mobile-mode d-lg-none">
          <form
            @submit.prevent="submitCoupon()"
            class="total-price-discount-btn d-flex d-md-none align-items-center text-color-444 align-self-center bg-none py-2 fontsize-small weight-bold"
          >
            <input
              type="text"
              class="discount-input bg-color-eai p-2 rounded"
              placeholder="کد تخفیف را وارد کنید"
              :disabled="!!discount"
              v-model="couponCode"
            />
            <div
              v-if="!discount"
              class="d-flex discount-btn text-color-white mr-1"
            >
              <span>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.9835 10.7865L18.581 9.38399C18.3411 9.14409 18.1473 8.67351 18.1473 8.34134V6.3483C18.1473 5.53633 17.483 4.87198 16.671 4.87198H14.6872C14.355 4.87198 13.8845 4.67822 13.6446 4.43831L12.2421 3.03581C11.67 2.46373 10.7288 2.46373 10.1567 3.03581L8.73579 4.43831C8.50511 4.67822 8.03454 4.87198 7.69314 4.87198H5.70933C4.89735 4.87198 4.23301 5.53633 4.23301 6.3483V8.33211C4.23301 8.66429 4.03924 9.13486 3.79934 9.37477L2.39683 10.7773C1.82475 11.3493 1.82475 12.2905 2.39683 12.8626L3.79934 14.2651C4.03924 14.505 4.23301 14.9756 4.23301 15.3077V17.2915C4.23301 18.1035 4.89735 18.7679 5.70933 18.7679H7.69314C8.02531 18.7679 8.49589 18.9616 8.73579 19.2015L10.1383 20.604C10.7104 21.1761 11.6515 21.1761 12.2236 20.604L13.6261 19.2015C13.866 18.9616 14.3366 18.7679 14.6688 18.7679H16.6526C17.4645 18.7679 18.1289 18.1035 18.1289 17.2915V15.3077C18.1289 14.9756 18.3227 14.505 18.5626 14.2651L19.9651 12.8626C20.5648 12.2997 20.5648 11.3586 19.9835 10.7865ZM7.49937 9.06105C7.49937 8.55356 7.91458 8.13835 8.42207 8.13835C8.92956 8.13835 9.34477 8.55356 9.34477 9.06105C9.34477 9.56853 8.93878 9.98375 8.42207 9.98375C7.91458 9.98375 7.49937 9.56853 7.49937 9.06105ZM8.9111 15.0863C8.7727 15.2247 8.59738 15.2893 8.42207 15.2893C8.24676 15.2893 8.07144 15.2247 7.93304 15.0863C7.66546 14.8187 7.66546 14.3758 7.93304 14.1082L13.4692 8.57202C13.7368 8.30443 14.1797 8.30443 14.4473 8.57202C14.7149 8.8396 14.7149 9.2825 14.4473 9.55008L8.9111 15.0863ZM13.9583 15.52C13.4416 15.52 13.0264 15.1047 13.0264 14.5973C13.0264 14.0898 13.4416 13.6746 13.9491 13.6746C14.4565 13.6746 14.8718 14.0898 14.8718 14.5973C14.8718 15.1047 14.4658 15.52 13.9583 15.52Z"
                    fill="white"
                  />
                </svg>
              </span>
              <input
                :disabled="disabled"
                type="submit"
                class="bg-trancparent text-color-white"
                value="ثبت کد تخفیف"
              />
            </div>
            <div v-else class="discount-btn text-color-white mr-3">
              <input
                type="button"
                @click="removeCoupon"
                class="bg-trancparent text-color-white"
                value="حذف کد تخفیف"
              />
            </div>
          </form>
        </section>

        <div class="col-12 col-lg-4 position-static">
          <div class="free-shipping-container pb-2 d-none d-lg-block">
            <freeShippingProgressBar :totalPrice="totalPrice" />
          </div>
          <section
            class="total-price d-lg-flex border-radius20 flex-lg-column justify-content-center justify-content-lg-between p-3 bg-color-eai position-static"
          >
            <div class="mb-2">
              <div class="text-color-444 d-flex justify-content-between mb-2">
                <span class="fontsize-medium weight-bold">
                  مجموع وزن ها :
                </span>
                <span>
                  <span class="fontsize-medium ml-1">{{
                    sumWeight | price
                  }}</span>
                  <span class="fontsize-small text-color-999"> گرم </span>
                </span>
              </div>
              <div class="text-color-444 d-flex justify-content-between mb-2">
                <span class="fontsize-medium weight-bold"
                  >مجموع قیمت های کالا :</span
                >
                <span>
                  <span class="fontsize-medium ml-1" v-if="totalPrice != 0"
                    >{{ (totalPrice + totalSaving) | price }}
                  </span>
                  <span class="fontsize-medium ml-1" v-else>0</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </span>
              </div>
              <div class="text-color-444 d-flex justify-content-between mb-2">
                <span class="fontsize-medium weight-bold"> تخفیف : </span>
                <span>
                  <span class="fontsize-medium ml-1">{{
                    totalSaving | price
                  }}</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </span>
              </div>

              <div
                v-if="discount && discount.discount != 0"
                class="text-color-444 d-flex justify-content-between mb-2"
              >
                <span class="fontsize-medium weight-bold">
                  تخفیف از کوپن :
                </span>
                <span>
                  <span class="fontsize-medium ml-1">
                    {{ discount.discount | price }}
                  </span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </span>
              </div>
              <div
                class="text-color-themeGreen d-flex justify-content-between mb-2"
              >
                <span class="fontsize-medium weight-bold">
                  پرداخت نهایی :
                </span>
                <span class="text-color-themeGreen">
                  <span
                    class="fontsize-large ml-1 weight-bold"
                    v-if="total != 0"
                    >{{ total | price }}</span
                  >
                  <span class="fontsize-medium ml-1" v-else>0</span>
                  <span class="fontsize-small">تومان</span>
                </span>
              </div>
            </div>
            <form
              @submit.prevent="submitCoupon()"
              class="total-price-discount-btn d-none d-lg-flex align-items-center text-color-444 align-self-center bg-none py-2 fontsize-small weight-bold"
            >
              <input
                type="text"
                class="discount-input"
                placeholder="کد تخفیف را وارد کنید"
                :disabled="!!discount"
                v-model="couponCode"
              />
              <div
                v-if="!discount"
                class="d-flex discount-btn text-color-white mr-1"
              >
                <span>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9835 10.7865L18.581 9.38399C18.3411 9.14409 18.1473 8.67351 18.1473 8.34134V6.3483C18.1473 5.53633 17.483 4.87198 16.671 4.87198H14.6872C14.355 4.87198 13.8845 4.67822 13.6446 4.43831L12.2421 3.03581C11.67 2.46373 10.7288 2.46373 10.1567 3.03581L8.73579 4.43831C8.50511 4.67822 8.03454 4.87198 7.69314 4.87198H5.70933C4.89735 4.87198 4.23301 5.53633 4.23301 6.3483V8.33211C4.23301 8.66429 4.03924 9.13486 3.79934 9.37477L2.39683 10.7773C1.82475 11.3493 1.82475 12.2905 2.39683 12.8626L3.79934 14.2651C4.03924 14.505 4.23301 14.9756 4.23301 15.3077V17.2915C4.23301 18.1035 4.89735 18.7679 5.70933 18.7679H7.69314C8.02531 18.7679 8.49589 18.9616 8.73579 19.2015L10.1383 20.604C10.7104 21.1761 11.6515 21.1761 12.2236 20.604L13.6261 19.2015C13.866 18.9616 14.3366 18.7679 14.6688 18.7679H16.6526C17.4645 18.7679 18.1289 18.1035 18.1289 17.2915V15.3077C18.1289 14.9756 18.3227 14.505 18.5626 14.2651L19.9651 12.8626C20.5648 12.2997 20.5648 11.3586 19.9835 10.7865ZM7.49937 9.06105C7.49937 8.55356 7.91458 8.13835 8.42207 8.13835C8.92956 8.13835 9.34477 8.55356 9.34477 9.06105C9.34477 9.56853 8.93878 9.98375 8.42207 9.98375C7.91458 9.98375 7.49937 9.56853 7.49937 9.06105ZM8.9111 15.0863C8.7727 15.2247 8.59738 15.2893 8.42207 15.2893C8.24676 15.2893 8.07144 15.2247 7.93304 15.0863C7.66546 14.8187 7.66546 14.3758 7.93304 14.1082L13.4692 8.57202C13.7368 8.30443 14.1797 8.30443 14.4473 8.57202C14.7149 8.8396 14.7149 9.2825 14.4473 9.55008L8.9111 15.0863ZM13.9583 15.52C13.4416 15.52 13.0264 15.1047 13.0264 14.5973C13.0264 14.0898 13.4416 13.6746 13.9491 13.6746C14.4565 13.6746 14.8718 14.0898 14.8718 14.5973C14.8718 15.1047 14.4658 15.52 13.9583 15.52Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <input
                  :disabled="disabled"
                  type="submit"
                  class="bg-trancparent text-color-white"
                  value="ثبت کد تخفیف"
                />
              </div>
              <div v-else class="discount-btn text-color-white mr-3">
                <input
                  type="button"
                  @click="removeCoupon"
                  class="bg-trancparent text-color-white"
                  value="حذف کد تخفیف"
                />
              </div>
            </form>
          </section>
          <div
            class="next-step-button-container d-none d-lg-flex position-sticky flex-column justify-content-lg-end align-items-baseline px-lg-3 py-2 mt-2"
          >
            <div
              id="next-btn-step-1-container"
              class="position-relative w-100 px-sm-0 mb-3"
            >
              <button
                id="next-btn-step-1"
                class="text-white continue-buy-process py-2 w-100 fontsize-medium text-center"
                @click="nextPage"
                data-placement="top"
                v-b-tooltip.focus
                :disabled="!isCartChecked || cartIsEmpty || disabled"
              >
                <span v-if="isCartChecked">ادامه فرآیند خرید</span>
                <span v-else-if="isCartChecked === null">در حال بارگزاری</span>
                <span v-else>عملیات به مشکل خورد</span>
              </button>
              <b-tooltip v-if="cartIsEmpty" target="next-btn-step-1-container"
                >{{ cartIsEmpty ? "سبد خرید شما خالی است" : "" }}
              </b-tooltip>
            </div>
            <p class="fontsize-small text-color-666 d-md-block d-none">
              افزودن کالا به سبد خرید به معنی رزرو آن نیست با توجه به محدودیت
              موجودی سبد خود را ثبت و خرید را تکمیل کنید.
            </p>
          </div>
        </div>
      </div>
      <div class="position-sticky button-container d-lg-none py-2 mt-2">
        <div v-if="!isLogin">
          <button
            id="loginButton"
            data-toggle="modal"
            data-target="#loginModal"
            style="bottom: 0"
            class="text-white continue-buy-process py-2 w-100 fontsize-medium text-center"
          >
            <span>ادامه فرآیند خرید</span>
          </button>
        </div>
        <div v-else>
          <button
            id="next-btn-step-1"
            style="bottom: 0"
            class="text-white position-sticky continue-buy-process py-2 w-100 fontsize-medium text-center"
            @click="nextPage"
            data-placement="top"
            v-b-tooltip.focus
            :disabled="!isCartChecked || cartIsEmpty || disabled"
          >
            <span v-if="isCartChecked">ادامه فرآیند خرید</span>
            <span v-else-if="isCartChecked === null">در حال بارگزاری</span>
            <span v-else>عملیات به مشکل خورد</span>
          </button>
        </div>
      </div>
      <div
        class="next-step-button-container d-flex d-lg-none bg-white mt-1 flex-column"
      >
        <div
          v-if="cartIsEmpty"
          id="next-btn-step-1-container"
          class="w-100 px-sm-0 mb-3"
        >
          <b-tooltip target="next-btn-step-1-container"
            >{{ cartIsEmpty ? "سبد خرید شما خالی است" : "" }}
          </b-tooltip>
        </div>
        <p class="fontsize-small text-color-666 text-start">
          افزودن کالا به سبد خرید به معنی رزرو آن نیست با توجه به محدودیت موجودی
          سبد خود را ثبت و خرید را تکمیل کنید.
        </p>
      </div>
    </div>

    <RespLoginModal />

    <div
      id="freeProductsPopUp"
      @click="closeFreeProductsModal"
      v-if="showFreeProductsPopUp && freeProducts && freeProducts.length"
    >
      <div id="content">
        <q> فقط با افزودن یکی از محصولات زیر هزینه ارسالت رایگان میشه </q>
        <div id="freeProductsSlider" class="swiper">
          <div
            class="swiper-container"
            v-if="!disabledSliders.includes('productTabSlider')"
          >
            <div class="swiper-wrapper">
              <Product
                class="swiper-slide"
                v-for="(product, index) in freeProducts"
                :key="index"
                :product="product"
              />
            </div>
          </div>
          <div>
            <div class="next-btn-holder d-none d-xl-block">
              <div class="next-btn">
                <RightChevIcon :fill="'#ffffff'" />
              </div>
            </div>
            <div class="prev-btn-holder d-none d-xl-block">
              <div class="prev-btn">
                <RightChevIcon :fill="'#ffffff'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="freeProductsPopUpBtn"
        @click="closeFreeProductsModal"
        type="button"
        class="close-popup"
      >
        X
      </button>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import CartItem from "@/parts/Front/components/cart/CartItem";
import cart from "@/parts/Front/components/cart/cart";
import { BTooltip, VBTooltip } from "bootstrap-vue";
import freeShippingProgressBar from "@/parts/Front/components/cart/freeShippingProgressBar";
import LoginUser from "@/parts/Front/components/LoginUser";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";

import axios from "axios";
import * as vueMethods from "../../../../libraries/VueMethods";
import { getProductWeight } from "@/libraries/functions";
import LeftChevIcon from "@/parts/Front/components/Icon/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";
import Swiper from "swiper/swiper-bundle.min";
import { getCookie, setCookie } from "../../../../libraries/VueMethods.js";

let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 10,
  },
};
export default {
  name: "CartStep1",
  inject: ["mediaQueries"],
  components: {
    CartItem,
    BTooltip,
    Product,
    RespLoginModal,
    RightChevIcon,
    LeftChevIcon,
    freeShippingProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    homeData: Object,
    isCartChecked: Boolean,
    couponCodeProp: String,
  },
  data() {
    return {
      showFreeProductsPopUp: false,
      cartHasFreeShippingItem: false,
      slider: null,
      disabled: false,
      couponCode: this.couponCodeProp,
      warning: [],
      deletedCartIds: [],
      // تا زمانی که وارنینگ ها لود نشد اجازه نده ادامه بده
      // false = یعنی ارور داد
    };
  },
  watch: {
    changeCart(newV) {
      if (newV && this.discount) {
        this.submitCoupon(true);
      }
    },
    cart(newV) {
      if (newV) this.checkExtraShipping();
    },
    // وقتی لاگین کرد دوباره چک بشه سبد خرید
    "$store.state.front.1.homeData.user.login"(isLoggedIn) {
      if (isLoggedIn == true) {
        // قرار دادن در وضعیت در حال برگزاری
        this.$emit("cartChecked", null);
        this.$nextTick(() => {
          this.init();
        });
      }
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$store.dispatch("front/getNotficationsFromServer");
    }
    this.init();
  },
  methods: {
    closeFreeProductsModal(e) {
      e.stopPropagation();
      if (
        e.target.id == "freeProductsPopUp" ||
        e.target.id == "freeProductsPopUpBtn"
      ) {
        this.showFreeProductsPopUp = false;
        setCookie("hidFreeProductsPopUp", true, 1);
      }
    },
    beforeInitSlider() {
      if (this.showFreeProductsPopUp && this.freeProducts) {
        this.initSlider();
      } else {
        setTimeout(() => {
          this.beforeInitSlider();
        }, 500);
      }
    },
    initSlider(kill = false) {
      this.initSwiper("productsFree", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#freeProductsSlider .swiper-container", {
          spaceBetween: 1,
          // centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: "#freeProductsSlider .next-btn-holder",
            prevEl: "#freeProductsSlider .prev-btn-holder",
          },
          breakpoints,
        });
      });
    },
    checkIsFreeProductsPopUp() {
      if (!getCookie("hidFreeProductsPopUp") && !this.cartHasFreeShippingItem) {
        if (!this.freeProducts) {
          this.$store.dispatch("front/getFreeShippingProductsFromDB");
        }
        this.showFreeProductsPopUp = true;
        this.beforeInitSlider();
      }
    },
    checkExtraShipping() {
      let findItem = this.cart.cartItems.find((cartItem) => {
        if (
          cartItem.variety.weight &&
          cartItem.variety.weight >
            Number(
              this.$store.getters["front/getHomeData"].settings.shipping_amount
                .overweight_shipping_amount
            )
        ) {
          return cartItem;
        } else if (
          cartItem.variety.product &&
          cartItem.variety.product.weight >
            Number(
              this.$store.getters["front/getHomeData"].settings.shipping_amount
                .overweight_shipping_amount
            )
        ) {
          return cartItem;
        }
        // return (
        //   cartItem.variety.weight >
        //   Number(
        //     this.$store.getters["front/getHomeData"].settings.shipping_amount
        //       .overweight_shipping_amount
        //   )
        // );
      });
      if (findItem) {
        this.$store.commit("front/setHasExtraWeight", true);
      }
    },
    getProductWeight,

    init() {
      console.log("init cart1");
      if (this.$store.getters["front/getLoginStatus"]) {
        console.log("init first if");
        this.$axios
          .get('customer/cart?show_warning=1&widgets=["shippings", "gateways"]')
          .then((response) => {
            this.$emit("cartChecked", true);
            const res = response.data.data;
            this.$emit("shippingsLoaded", res.widgets.shippings);
            this.$emit("gatewaysLoaded", res.widgets.gateways);
            this.cartHasFreeShippingItem = res.carts_showcase.has_free_shipping;
            this.$emit("setFreeShipping", this.cartHasFreeShippingItem);
            this.checkIsFreeProductsPopUp();
            this.$store.commit("front/setUserCart", {
              priority: 2,
              cart: new cart(this.$root, res.carts, "server"),
            });
            this.warning = res.messages.warning;
            this.deletedCartIds = res.messages.deleted_cart_ids;
            this.$emit("setReservations", res.reservations);
          })
          .catch((error) => {
            this.$emit("cartChecked", false);
          });
      } else if (
        !this.$store.getters["front/getLoginStatus"] &&
        this.$store.getters["front/getHomeData"]
      ) {
        console.log("init reloadCart");

        this.reloadCart();
      } else {
        this.$emit("cartChecked", true);
      }
    },
    nextPage() {
      // اگر لاگین نبود مدال نشون بده
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      let fixedCarts = this.getFixedCartItems();
      this.$store.commit("front/setUserCart", {
        priority: 3,
        cart: new cart(this.$root, fixedCarts, "server"),
      });
      this.$emit("next-page");
    },
    getFixedCartItems() {
      let carts = this.cart.cartItems;
      return carts.filter((item) => {
        if (this.deletedCartIds.includes(item.id)) {
          return;
        } else {
          return item;
        }
      });
    },
    async submitCoupon(reCalculating = false) {
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      this.disabled = true;
      try {
        const response = await this.$axios.post("customer/coupon/verify", {
          code: this.couponCode,
          total_price: this.totalPrice,
        });
        this.$store.commit("front/setChangeCartInfo", false);

        this.$emit("couponApplied", this.couponCode);
        !reCalculating &&
          this.$root.notify("کد تخفیف با موفقیت اعمال شد", "success");
        this.$store.commit(
          "front/setDiscountCoupen",
          response.data.data.discount
        );
        // this.$emit("discountApplied", );
      } catch (error) {
        if (reCalculating) {
          this.removeCoupon();
        } else {
          this.$root.notify(error);
        }
      }

      this.disabled = false;
    },
    async reloadCart() {
      axios.defaults.headers.common["Authorization"] =
        vueMethods.getCookie("Authorization");
      console.log(
        "cart.getCartRequestQueryString()",
      
        cart.getCartRequestQueryString()
      );

      const response = await axios.get("/all/cart/get", {
        params: { ...cart.getCartRequestQueryString() },
      });
      const data = response.data.data;
      if (data.user.user) {
        this.$store.commit("front/setLoginStatus", data.user);
      } else {
        console.log(
          "cart.getInitialCartForCookieMode(data.cart_request)",
          cart.getInitialCartForCookieMode(data.cart_request)
        );

        let initialCarts = cart.getInitialCartForCookieMode(data.cart_request);
        console.log(
          'new cart(this, initialCarts, "cookie")',
          new cart(this, initialCarts, "cookie")
        );

        this.$store.commit("front/setUserCart", {
          priority: 0,
          cart: new cart(this, initialCarts, "cookie"),
        });
        this.$emit("cartChecked", true);
      }
    },
    removeCoupon() {
      this.$store.commit("front/setDiscountCoupen", null);

      // this.$emit("discountApplied", null);
      this.$emit("couponApplied", "");
    },
    recalculateCoupon() {
      if (this.discount) {
        this.submitCoupon(true);
      }
    },
  },
  computed: {
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    freeProducts() {
      return this.$store.getters["front/getFreeShippingProducts"];
    },
    changeCart() {
      return this.$store.getters["front/getChangeCartInfo"];
    },
    discount() {
      return this.$store.getters["front/getDiscountCoupen"];
    },

    defualtWeight() {
      return this.homeData != null
        ? Number(this.homeData.settings.shipping_amount.defualt_product_weight)
        : -1;
    },
    sumWeight() {
      let sum = 0;
      if (
        this.cart != null &&
        this.cart.cartItems != null &&
        this.cart.cartItems != ""
      ) {
        this.cart.cartItems.forEach((cart) => {
          sum =
            sum +
            Number(this.getProductWeight(cart, this.defualtWeight)) *
              cart.quantity;
        });
      } else {
        sum = 0;
      }
      return sum;
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    cartIsEmpty() {
      if (!this.cart) {
        return false;
      }
      let fixedCartItems = this.getFixedCartItems();

      return fixedCartItems.length && fixedCartItems.length === 0;
    },
    total() {
      return this.cart
        ? this.cart.getTotalPrice(this.deletedCartIds) -
            (this.discount ? this.discount.discount : 0)
        : 0;
    },
    totalSaving() {
      return this.cart ? this.cart.getTotalSaving(this.deletedCartIds) : 0;
    },
    totalPrice() {
      return this.cart ? this.cart.getTotalPrice(this.deletedCartIds) : 0;
    },
  },
};
</script>
<style scoped lang="scss">
.button-container {
  bottom: 0;
}
.cart-table-head {
  font-size: 14px !important;
}

.discount-btn {
  background: var(--color-theme);
  padding: 6px 8px;
  color: #fff;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
}

.discount-btn span {
  transform: translateY(-1px);
  display: block;
}

.discount-input {
  padding: 6px 5px;
}

.discount-input::placeholder {
  color: #8b8b8b;
}

.discount-btn:disabled {
  opacity: 0.7;
  background: #dcdcdc;
}

.discount-btn {
  transition: 200ms all;
}

.discount-btn:hover {
  color: #ffffff !important;
  background: var(--color-theme);
}

.continue-buy-process {
  color: white;
  font-size: 14px;
  height: 50px;
  transition: 0.3s;
  border-radius: 15px;
}

/* .continue-buy-process:hover {
  background-color: var(--color-theme4);
} */
@media screen and (max-width: 768px) {
  #next-btn-step-1-container {
    bottom: 0;
    width: 100% !important;
  }
}

div#freeProductsPopUp {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  background: #000000a8;
  display: flex;
  align-items: center;
  justify-content: center;
}

div#freeProductsPopUp #content {
  width: 80%;
  margin: 24px;
  background: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-height: 90vh;
}

div#freeProductsSlider {
  width: 100%;
  position: relative;
}

.recommended-content-bg {
  border-radius: 40px;
  position: absolute;
  width: 100%;
  height: 70%;
  top: 0%;
}

.prev-btn-holder {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%) rotateY(180deg);
  background-color: var(--color-theme);
  border-radius: 50%;
  padding: 10px;
}

.next-btn-holder {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background-color: var(--color-theme);
  border-radius: 50%;
  padding: 10px;
}

/* .prev-btn-icon {
  position: absolute;
  top: 53%;
  right: 8%;
  transform: translateY(-50%);
}
.next-btn-icon {
  position: absolute;
  top: 53%;
  left: 8%;
  transform: translateY(-50%);
} */
div#freeProductsPopUp #content q {
  font-size: 22px;
  margin-bottom: 0;
  background: #f172ac;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  color: white;
  position: relative;
  bottom: 16px;
}

#freeProductsPopUp .close-popup {
  position: absolute;
  top: 6%;
  right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  background-color: var(--color-theme);
  padding: 10px 15px 5px 15px;
  border-radius: 50%;
  font-weight: bold;
}

.next-step-button-container {
  bottom: 0;
}

@media screen and (min-width: 992px) {
  .next-step-button-container {
    position: static !important;
  }
}

@media (max-width: 768px) {
  div#freeProductsPopUp #content q {
    font-size: 12px !important;
    text-align: center;
  }
  div#freeProductsPopUp #content {
    width: 95%;
  }
}

div#freeProductsSlider article {
  box-shadow: 0 0 5px #1216;
}

#freeProductsSlider .swiper-container {
  padding: 8px;
}
</style>
