<template>
    <div>
        
    </div>
</template>
<script>
export default{
    mounted(){
        localStorage.setItem("inviteLink",this.$route.params.id);
        this.$router.push("/#loginModalOpened")
    }   
}
</script>