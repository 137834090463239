<template>
  <main class="questionLandingPage"  id="questionLandingPage">
    <div
      v-if="questionData"
      class="recommended-content-item position-relative"
      id="home"
    >
      <div v-if="questions" id="backContainer">
        <template v-for="index in questions.length">
          <img
            v-if="windowWidth > 768"
            :key="index"
            class="desktopBack"
            :id="`pat${index}`"
            src="@/assets/images/pat.png"
          />
          <img
            v-else
            :key="index"
            class="mobileBack"
            :id="`pat${index}`"
            src="@/assets/images/patMobile.png"
          />
        </template>
      </div>
      <div id="mainSwiper">
        <div
          class="swiper-container"
          v-if="!disabledSliders.includes('recommendedProductSlider')"
        >
          <div class="swiper-wrapper">
            <div v-if="!StartQuestions" class="swiper-slide starter">
              <h4 style="font-weight: bold;">
                {{ questionData.customer_title }}
              </h4>
              <h5 style="text-align: center;">
                {{ questionData.customer_text }}
              </h5>
              <button
                @click="StartQuestion()"
                id="startBtn"
                style="--clr:#00A3A7"
              >
                <span>شروع</span><i></i>
              </button>
            </div>
            <template
              v-else-if="showCoupenSlide == false && StartQuestions == true"
            >
              <div
                v-for="(item, index) in questions"
                :key="index"
                class="swiper-slide"
              >
                <div class="questionBox">
                  <h3>
                    <img
                      src="@/assets/images/question.gif"
                      alt="سوال بندیتو از شما"
                    />
                    <span>{{ item.question }}</span>
                  </h3>
                  <input
                    :ref="`answer_${item.id}`"
                    v-if="item.data == null"
                    type="text"
                  />
                  <div v-else-if="item.type == 'options'" class="boxRadio">
                    <template v-for="(key, index2) in item.data">
                      <input
                        type="radio"
                        :ref="`answer_${item.id}`"
                        :name="`answer_${item.id}`"
                        :id="`answer_${item.id}${index2}`"
                        :value="key"
                        :key="index + 'a'"
                        @click="saveAnswer(item.id, 'radio', index2)"
                      />
                      <label
                        :for="`answer_${item.id}${index2}`"
                        :key="index + 'b'"
                        >{{ key }}</label
                      >
                    </template>
                  </div>
                  <div v-else-if="item.type == 'checkbox'" class="checkboxes">
                    <label
                      v-for="(key, index3) in item.data"
                      :key="index + 'c'"
                      class="flipBox"
                      aria-label="Checkbox 1"
                    >
                      <input
                        @click="saveAnswer(item.id, 'checkbox', index3)"
                        :ref="`answer_${item.id}`"
                        :value="key"
                        type="checkbox"
                      />
                      <span>
                        {{ key }}
                      </span>
                      <div class="flipBox_boxOuter">
                        <div class="flipBox_box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                      <div class="flipBox_shadow"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="swiper-slide lastSlide">
                <label for=""
                  style="text-align: center;"
                >
                من مهسا جلالی هستم ، حالا میتونید شماره موبایلتونو وارد کنید تا برای مشاوره کاملا رایگان با شما تماس بگیرم
                </label
                >
                <input placeholder="شماره موبایل" v-model="userPhone" type="tel" />
                <button @click="sendCampaign()">ثبت</button>
              </div>
            </template>
            <div v-if="showCoupenSlide" class="swiper-slide showCopen">
              <h4>
                از توجه شما متشکریم؛ هدیه ناقابل ما برای شما کد تخفیف زیر است
              </h4>
              <div>
                <span @click="copyText()">{{ coupon }}</span>
                <a @click="copyText()">
                  <i class="fa fa-copy"></i>
                </a>
              </div>

              <router-link id="homeLink" to="/">
                <i class="fa fa-home"></i>
                <span>ورود به وبسایت</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="StartQuestions && !showCoupenSlide"
        class="swiper-button-next"
      >
        سوال بعدی
      </div>
      <div
        v-show="StartQuestions && !showCoupenSlide"
        class="swiper-button-prev"
      >
        سوال قبلی
      </div>
    </div>
  </main>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";

export default {
  components: {
    ZArrowBold,
  },
  mounted() {
    
    this.$nextTick(() => {
      this.checkIosConfig();
    });
    this.getCampaign();
    this.calcWindowWidth();
    window.addEventListener("resize", this.calcWindowWidth);
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "other";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Ios";
      }

      return "other";
    },
    checkIosConfig() {
      if (this.getMobileOperatingSystem() == "Ios") {
        alert(1)
        setTimeout(() => {
          document.querySelector(".questionLandingPage")
          .classList.add("IosConfig")
        }, 50);
      }
    },
    calcWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    copyText() {
      navigator.clipboard.writeText(this.coupon);
      this.$root.notify("کپی شد", "success");
    },
    saveAnswer(id, type, value) {
      if (type == "radio") {
        this.answers[id] = value;
      } else {
        if (this.answers[id] == undefined) {
          this.answers[id] = [];
          this.answers[id].push(value);
        } else {
          let iof = this.answers[id].indexOf(value);
          if (iof != -1) {
            this.answers[id].splice(iof, 1);
          } else {
            this.answers[id].push(value);
          }
        }
      }
    },
    sendCampaign() {
      let fd = new FormData();
      if (!this.userPhone) {
        return this.$root.notify("لطفا شماره تماس خود را وارد کنید", "warning");
      } else if (this.userPhone.toString().length != 11) {
        return this.$root.notify(" شماره تماس باید 11 رقمی باشد", "warning");
      }
      fd.append("mobile", this.userPhone);
      let allowed = true;
      this.questions.forEach((question, index) => {
        if (question.data) {
          if (question.type == "options") {
            if (!this.answers[question.id]) {
              allowed = false;
              this.$root.notify(
                `لطفا پاسخ سوال ${index + 1} را بدهید`,
                "warning"
              );
              return this.slider.slideTo(index);
            } else {
              fd.append(`answers[${index}][question_id]`, question.id);
              fd.append(`answers[${index}][answer]`, this.answers[question.id]);
            }
          } else {
            if (
              !this.answers[question.id] ||
              (this.answers[question.id] &&
                this.answers[question.id].length == 0)
            ) {
              allowed = false;
              this.$root.notify(
                `لطفا پاسخ سوال ${index + 1} را بدهید`,
                "warning"
              );
              return this.slider.slideTo(index);
            } else {
              fd.append(`answers[${index}][question_id]`, question.id);
              fd.append(
                `answers[${index}][answer]`,
                this.answers[question.id].toString()
              );
            }
          }
        } else {
          if (!this.$refs[`answer_${question.id}`][0].value) {
            allowed = false;

            this.$root.notify(
              `لطفا پاسخ سوال ${index + 1} را بدهید`,
              "warning"
            );
            return this.slider.slideTo(index);
          } else {
            fd.append(`answers[${index}][question_id]`, question.id);
            fd.append(
              `answers[${index}][answer]`,
              this.$refs[`answer_${question.id}`][0].value
            );
          }
        }
      });
      if (allowed) {
        fd.append("campaign_id", this.$route.params.id);
        this.$axios
          .post("front/save-campaign-answers", fd)
          .then((res) => {
            this.$root.notify(
              "درخواست مشاوره رایگانتون ثبت شد"
              , "success");
            if(Boolean(res.data.data.coupon)){

              this.coupon = res.data.data.coupon;
              this.showCoupenSlide = true;
              this.initSlider();
              window.removeEventListener("keydown", this.handlerNextQuestion);
            }
          })
          .catch((error) => {
            this.$root.notify(error);
          });
      }
    },
    handlerNextQuestion(e) {
      if (e.keyCode == 9) {
        //tab pressed
        e.preventDefault(); // stops its action
      }
      if (e.keyCode == 13 || e.keyCode == 39 || e.keyCode == 40) {
        this.slider.slideNext();
      } else if (e.keyCode == 37 || e.keyCode == 38) {
        this.slider.slidePrev();
      }
    },
    StartQuestion() {
      this.StartQuestions = true;
      this.initSlider();
      window.addEventListener("keydown", this.handlerNextQuestion);
      this.handlerBack("+");
    },
    handlerBack(flag, force = null) {
      if (flag == "*") {
        this.gotoTopPx = force * 250;
      } else if (flag == "+") {
        this.gotoTopPx += 250;
      } else if (flag == "-") {
        this.gotoTopPx -= 250;
      }
      this.questions.forEach((e, i) => {
        this.backImage(i + 1).style.bottom = `${this.gotoTopPx}px`;
      });
    },
    backImage(id) {
      return document.getElementById(`pat${id}`);
    },
    getCampaign() {
      this.$axios
        .get(`front/get-campaign-questions/${this.$route.params.id}`)
        .then((res) => {
          let list = [];
          res.data.data.campaign.questions.forEach((element) => {
            let simpleOBj = element;
            if (element.data) {
              simpleOBj.data = JSON.parse(element.data);
            }
            list.push(simpleOBj);
          });
          this.questions = list;
          this.questionData = res.data.data.campaign;
          this.initSlider();
        });
    },
    initSlider(kill = false) {
      let fakeThis = this;
      this.initSwiper("questionSliders", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(".questionLandingPage .swiper-container", {
          spaceBetween: 1,
          effect: "slide",
          direction: "vertical",
          //   centeredSlides: true,
          loop: false,
          speed: 1500,
          allowTouchMove: true,
          slidesPerView: 1,
          autoplay: false,
          on: {
            activeIndexChange(e) {
              fakeThis.handlerBack("*", e.activeIndex + 1);
            },
          },
          navigation: {
            nextEl: ".questionLandingPage .swiper-button-next",
            prevEl: ".questionLandingPage .swiper-button-prev",
          },
        });
      });
    },
  },
  data() {
    return {
      windowWidth: 0,
      starterBack: 1,
      gotoTopPx: 0,
      showCoupenSlide: false,
      answers: {},
      slider: null,
      userPhone: null,
      StartQuestions: false,
      questionData: null,
      questions: null,
      coupon: null,
    };
  },
};
</script>
<style scoped>
.swiper-button-prev {
  left: 90vw;
}
.swiper-button-prev,
.swiper-button-next {
  position: fixed !important;
  top: 85vh;
  min-width: 90px;
  color: white !important;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
  transition: all 1s;
}

.swiper-button-next {
  background: #e83c89;
  right: 15%;
}
.swiper-button-next:hover {
  background: #e8217b;
}
.swiper-button-prev {
  background-color: #444444a1;
  right: calc(15% + 120px);
}
@media (max-width: 768px) {
  .swiper-button-prev {
    left: unset !important;
    right: calc(15% + 120px) !important;
  }
}
.swiper-button-prev:hover {
  background-color: #444;
}
.questionLandingPage .swiper-button-prev:after,
.questionLandingPage .swiper-button-next:after {
  display: none;
}
.boxRadio label {
  margin: 0;
}
.boxRadio input {
  box-shadow: unset !important;
  width: max-content !important;
  transform: scale(2);
  accent-color: var(--color-themepinky);
}
.boxRadio {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  padding: 24px;
}
.questionBox h3 img {
  width: 50px;
}
.questionBox textarea {
  resize: none;
  height: 200px;
}
.questionBox h3 {
  display: flex;
  align-items: center;
  gap: 16px;
}
.questionBox input,
.questionBox textarea {
  border-radius: 6px;
  width: 85%;
  box-shadow: 0 0 5px 0px black;
  padding: 12px;
  margin: 10px;
  margin-right: 2.5%;
  outline: none;
}
.lastSlide button {
  background: #00a3a7;
  color: white;
  padding: 10px 50px;
}
.lastSlide input {
  box-shadow: 0 0 4px #121;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
}
.lastSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.questionLandingPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
div#mainSwiper {
  min-height: 50vh;
  width: 50%;
  position: absolute;
  right: 15%;
  transform: translateY(150px);
}
/*chechbox style*/
*:not(:empty) {
  transform-style: preserve-3d;
}

.checkboxes {
  width: 100%;
  max-width: 180px;
  padding: 4px;
}

.flipBox {
  position: relative;
  display: block;
  text-align: right;
  margin: 2em auto;
  cursor: pointer;
}
.flipBox:active > .flipBox_boxOuter {
  height: 25%;
}
.flipBox span {
  padding-right: 2.5em;
}
.flipBox > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: fixed;
  top: -100vmax;
  opacity: 0;
}
.flipBox > input:checked ~ .flipBox_boxOuter {
  -webkit-animation: flipBox1 1s forwards linear;
  animation: flipBox1 1s forwards linear;
}
.flipBox > input:checked ~ .flipBox_boxOuter .flipBox_box {
  -webkit-animation: flipBox2 1s forwards ease-in-out;
  animation: flipBox2 1s forwards ease-in-out;
}
.flipBox > input:checked ~ .flipBox_shadow {
  -webkit-animation: flipBox3 1s forwards ease-in-out;
  animation: flipBox3 1s forwards ease-in-out;
}
.flipBox > input:not(:checked) ~ .flipBox_boxOuter {
  -webkit-animation: flipBox4 1s forwards linear;
  animation: flipBox4 1s forwards linear;
}
.flipBox > input:not(:checked) ~ .flipBox_boxOuter .flipBox_box {
  -webkit-animation: flipBox5 1s forwards ease-in-out;
  animation: flipBox5 1s forwards ease-in-out;
}
.flipBox > input:not(:checked) ~ .flipBox_shadow {
  -webkit-animation: flipBox6 1s forwards ease-in-out;
  animation: flipBox6 1s forwards ease-in-out;
}
.flipBox > input:disabled ~ * {
  cursor: not-allowed;
}
.flipBox > input:disabled ~ .flipBox_boxOuter {
  height: 0%;
}
.flipBox > input:disabled ~ .flipBox_boxOuter .flipBox_box > div:nth-child(6) {
  background-color: #ccc;
}
.flipBox
  > input:disabled
  ~ .flipBox_boxOuter
  .flipBox_box
  > div:nth-child(-n + 5),
.flipBox > input:disabled ~ .flipBox_boxOuter ~ .flipBox_shadow {
  display: none;
}
.flipBox > input:focus-visible ~ span {
  outline: 1px #000 solid;
  outline-offset: 0.25em;
}
.flipBox_boxOuter {
  position: absolute;
  right: 0;
  top: 50%;
  width: 2em;
  height: 2em;
  transform-origin: top;
  transition: height 0.25s;
}
.flipBox_box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2em;
  height: 100%;
  transform: translate(-50%, -50%);
}
.flipBox_box > div {
  position: absolute;
  width: 2em;
  box-shadow: 0 0 0.25em #0007 inset;
}
.flipBox_box > div:nth-child(1) {
  background-image: linear-gradient(var(--color-themepinky), white);
  transform: rotateY(0deg) translateX(50%) rotateY(90deg);
  height: 100%;
}
.flipBox_box > div:nth-child(2) {
  background-image: linear-gradient(var(--color-themepinky), white);
  transform: rotateY(90deg) translateX(50%) rotateY(90deg);
  height: 100%;
}
.flipBox_box > div:nth-child(3) {
  background-image: linear-gradient(var(--color-themepinky), white);
  transform: rotateY(180deg) translateX(50%) rotateY(90deg);
  height: 100%;
}
.flipBox_box > div:nth-child(4) {
  background-image: linear-gradient(var(--color-themepinky), white);
  transform: rotateY(270deg) translateX(50%) rotateY(90deg);
  height: 100%;
}
.flipBox_box > div:nth-child(5) {
  background-color: var(--color-themepinky);
  transform: rotateX(90deg) translateY(-1em);
  transform-origin: top;
  height: 2em;
}
.flipBox_box > div:nth-child(5)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: white;
  -webkit-clip-path: polygon(
    12.5% 56.25%,
    25% 43.75%,
    37.5% 56.25%,
    75% 18.75%,
    87.5% 31.25%,
    37.5% 81.25%,
    12.5% 56.25%
  );
  clip-path: polygon(
    12.5% 56.25%,
    25% 43.75%,
    37.5% 56.25%,
    75% 18.75%,
    87.5% 31.25%,
    37.5% 81.25%,
    12.5% 56.25%
  );
}
.flipBox_box > div:nth-child(6) {
  bottom: 0;
  background-color: white;
  transform: rotateX(90deg) translateY(1em);
  transform-origin: bottom;
  height: 2em;
}
.flipBox_shadow {
  position: absolute;
  right: 0;
  top: 50%;
  width: 2em;
  height: 2em;
  transform: translateY(-50%) translateZ(-1px);
  background-color: #0007;
  filter: blur(0.2em);
}
@-webkit-keyframes flipBox1 {
  0%,
  100% {
    transform: rotateX(90deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flipBox1 {
  0%,
  100% {
    transform: rotateX(90deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flipBox2 {
  from {
    transform: translate(-50%, -50%) rotateX(0deg) rotateY(180deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
}
@keyframes flipBox2 {
  from {
    transform: translate(-50%, -50%) rotateX(0deg) rotateY(180deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
}
@-webkit-keyframes flipBox3 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flipBox3 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flipBox4 {
  0%,
  100% {
    transform: rotateX(90deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flipBox4 {
  0%,
  100% {
    transform: rotateX(90deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flipBox5 {
  from {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-360deg) rotateY(180deg);
  }
}
@keyframes flipBox5 {
  from {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-360deg) rotateY(180deg);
  }
}
@-webkit-keyframes flipBox6 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flipBox6 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@media (prefers-reduced-motion) {
  .flipBox_boxOuter,
  .flipBox_box,
  .flipBox_shadow {
    -webkit-animation-play-state: paused !important;
    animation-play-state: paused !important;
  }

  .flipBox_boxOuter {
    transition: none;
  }
}
.questionBox h3 span {
  font-size: 20px;
  font-weight: 700;
}
#startBtn {
  position: relative;
  background: #444;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  letter-spacing: 0.1rem;
  font-size: 1rem;
  padding: 1rem 3rem;
  transition: 0.2s;
}

#startBtn:hover {
  letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: var(--clr);
  /* box-shadow: 0 0 35px var(--clr); */
  animation: box 3s infinite;
}

#startBtn::before {
  content: "";
  position: absolute;
  inset: 2px;
  background: #eb6fa7;
}
.swiper-slide{
  min-height: 50vh;
}
#startBtn span {
  position: relative;
  z-index: 1;
}
#startBtn i {
  position: absolute;
  inset: 0;
  display: block;
}

#startBtn i::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 80%;
  top: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

#startBtn:hover i::before {
  width: 15px;
  left: 20%;
  animation: move 3s infinite;
}

#startBtn i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  left: 20%;
  bottom: -2px;
  border: 2px solid var(--clr);
  background: #272822;
  transition: 0.2s;
}

#startBtn:hover i::after {
  width: 15px;
  left: 80%;
  animation: move 3s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes box {
  0% {
    box-shadow: #27272c;
  }
  50% {
    box-shadow: 0 0 25px var(--clr);
  }
  100% {
    box-shadow: #27272c;
  }
}
.starter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.showCopen div a i {
  color: white;
}

.showCopen div a {
  border: 1px solid #1213;
  display: flex;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 4px 0 0 4px;
  background: black;
  color: white;
}
.showCopen div span {
  border: 1px solid #1213;
  text-align: center;
  padding: 4px 12px;
  box-shadow: 0 0 4px #1213;
  border-radius: 0 4px 4px 0;
}
.showCopen div {
  display: grid;
  align-items: center;
  grid-template-columns: 100px 50px;
  gap: 0;
}
.showCopen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
a#homeLink {
  color: white;
  background-color: var(--color-themepinky);
  width: max-content;
  padding: 12px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 768px) {
  div#mainSwiper {
    width: 80%;
    right: 10%;
  }
  .swiper-button-prev {
    left: unset;
    right: 50% !important;
    transform: translateX(-10px);
  }
  .swiper-button-next {
    background: #e83c89;
    right: 50%;
    transform: translateX(105px);
  }
}
/*pattern style*/
div#backContainer {
  position: fixed;
  left: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
}
div#backContainer img {
  width: 100%;
  position: relative;
  bottom: 0;
  transition: all 1s;
}
.mobileBack {
  opacity: 0.2;
}
.IosConfig .boxRadio label {
  margin-right: 16px;
  
}
.IosConfig .boxRadio{
  row-gap:10px
}
.lastSlide input{
  font-size: 16px;
}
</style>
