<template>
  <section id="game-container">
    <div class="navWheel">
      <router-link class="topbtn" to="/"> صفحه اصلی </router-link>

      <figure>
        <img src="../../../assets/images/whitelogo.png" alt="" />
      </figure>

      <div>
        <button
          v-if="!isLogin"
          type="button"
          class="topbtn"
          data-toggle="modal"
          data-target="#loginModal"
        >
          ورود و عضویت
        </button>
        <LoginUser v-else />

        <!-- مودال ورود -->
        <RespLoginModal />
      </div>
    </div>
    <div class="main">
      <figure id="background-game">
        <img class="img-fluid d-none d-md-inline-block" src="../../../assets/images/wheel/W.gif" alt="">
        <img class="img-fluid d-md-none" src="../../../assets/images/wheel/H.gif" alt="">
      </figure>
      <div v-if="NotAllowed" class="error-message">
        <figure class="figure-error">
          <img src="../../../assets/images/wheel/template.png" />
        </figure>
        <p>⚠️ {{ NotAllowed }}</p>
      </div>

      <div class="isLogin" v-if="!NotAllowed && !isLogin">
        <figure class="figure-error">
          <img src="../../../assets/images/wheel/template.png" />
        </figure>
        <p class="text-error">
          🌿 بندیتویی عزیز 🌿
          <br />
          وقتشه شانستو امتحان کنی! 😎
          <br />
          برای شرکت تو گردونه شانس و ثبت جایزه‌های خفنت، کافیه وارد پنل کاربری
          بشی! 🎯🎁
          <br />
          ✨ یه عالمه جایزه منتظرته ، بزن بریم ✨
          <br />
          🔹 بچرخون ببین چی گیرت میاد! 🔥
        </p>
        <div>
          <button
            v-if="!isLogin"
            type="button"
            class="topbtn"
            data-toggle="modal"
            data-target="#loginModal"
          >
            بچرخون ببین چی میبری! ❤🔥
          </button>
          <LoginUser v-else />

          <!-- مودال ورود -->
          <RespLoginModal />
        </div>
      </div>

      <div class="costGame" v-if="costGame">
        <div
          class="box-cost text-error"
          v-if="costGame.bon_count == 0 && costGame.score_count == 0"
        >
          🎯 حالا وقت هیجان رسیده! 🎯
          <br />
          نفستو حبس کن... 😎
          <br />
          گردونه رو بچرخون و ببین کدوم جایزه خوش‌شانس نصیبت میشه! 🎁✨
          <br />
          🔥 آماده‌ای که شانستو امتحان کنی؟
          <br />
          🔹 بچرخون ببین چی گیرت میاد! 🔥

          <button
            class="externalBtn hb-fill-bottom"
            @click="onCanvasRotateStart"
          >
          بچرخون ببین چی میبری! ❤🔥
          </button>
        </div>
        <div class="box-cost container" v-else>
          <div class="text-center">
            <p class="boldText"> بندیتویی عزیز! 💫  </p>
            <p>
              <span>
                برای اینکه گردونه شانس رو بچرخونی، فقط کافیه
               </span>
                <span class="boldText" v-if="costGame.bon_count > 0">
                  {{ costGame.bon_count }} بن کارت
                </span>
                <span v-if="costGame.bon_count > 0 && costGame.score_count > 0">
                  و
                </span>
                <span class="boldText" v-if="costGame.score_count > 0">
                  {{ costGame.score_count }} امتیاز
                </span>
                <span>
                  امتیاز خرج کنی! 🔥
                </span>
            </p>
            <p>
              🎯 حالا وقت هیجان رسیده! 🎯
            </p>
            <p>
              🔥 آماده‌ای که شانستو امتحان کنی؟
            </p>
            <p>
              🔹 بچرخون ببین چی میبری 🔥
            </p>
          </div>

          <button
            class="externalBtn hb-fill-bottom mt-4"
            @click="onCanvasRotateStart"
          >
          بچرخون ببین چی میبری! ❤🔥
          </button>
        </div>
      </div>

      <FortuneWheel
        ref="child"
        @onCanvas="onCanvasRotateStart"
        @onRotateEnd="onRotateEnd"
        v-if="hasSpin && !NotAllowed && isLogin && prizes.length"
        :prizes="prizes"
        :prizeId="prizeid"
        :winRain="winRain"
      />
      <div v-else-if="!hasSpin">
        <span> ⚠️ کاربر گرامی، گردونه شانس غیر فعال است </span>
      </div>
    </div>
    <ModalDialog
      @visible="visible"
      :statusVisible="showModal"
      :title="modalData.title"
      :message="modalData.message"
      :discountCode="modalData.discountCode"
    />
  </section>
</template>

<script>
import { collection } from "firebase/firestore/lite";
import FortuneWheel from "../components/wheelOfFortune/index.vue";
import ModalDialog from "../components/wheelOfFortune/modalDialog.vue";
import axios from "axios";
import RespLoginModal from "../components/RespLoginModal.vue";
import LoginUser from "../components/LoginUser.vue";
export default {
  components: {
    FortuneWheel,
    ModalDialog,
    RespLoginModal,
    LoginUser,
  },
  data() {
    return {
      prizeid: "",
      showModal: false,
      modalData: {
        title: "",
        message: "",
        discountCode: "",
      },
      flagSpin: false,
      alertName: "",
      codePesentCopon: null,
      winRain : true
    };
  },
  computed: {
    prizes() {
      const allSpinRewards = this.$store.getters["front/getAllSpinRewards"];
      let result = [];
      console.log(allSpinRewards, "this is allSpinRewards :");
      if (!allSpinRewards) return [];
      result = allSpinRewards.map((reward, index) => ({
        id: reward.id,
        name: this.getRewardName(reward),
        value: reward.reward_value,
        // bgColor: index % 2 === 0 ? "rgb(250, 171, 207)" : "rgb(226, 104, 159)",
        // gradinetLine:[{width: 1 , color : '#000000'} , {width: 0.75 , color : '#850385'} , {width: 0.5 , color : '#f506f5'} , {width: 0.25 , color : '#fff'}],
        color: index % 2 === 0 ? "#000" : "#fff",
        location: this.location(index),
      }));
      console.log(result, "result");
      return result;
    },

    hasSpin() {
      const homeData = this.$store.getters["front/getHomeData"];
      let hasSpin = false;
      if (homeData && homeData.active_games) {
        hasSpin = homeData.active_games.find((o) => o.title === "spin");
      }
      console.log(hasSpin, "this is hasSpin :");
      return hasSpin;
    },
    costGame() {
      return this.$store.getters["front/getGamePrice"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    NotAllowed() {
      return this.$store.getters["front/getErrorNotAllowed"];
    },
  },
  methods: {
 
    //  getRewardColor(type) {
    //    const colors = {
    //      gift_wallet: "#faabcf",
    //      coupon_flat: "#fdf688",
    //      coupon_percentage: "#faabcf",
    //      nothing: "#fdf688",
    //    };
    //    return colors[type] || "#ffffff";
    //  },
    //  spinExternalWheel() {
    //    this.$refs.FortuneWheel.spinWheel();
    //  },
    location(index) {
      return { startLo: index, endLo: index + 1 };
    },
    getRewardName(reward) {
      switch (reward.reward_type) {
        case "gift_wallet":
          return `کیف پول  ${Number(reward.reward_value).toLocaleString(
            "en-US"
          )} تومان`;
        case "coupon_flat":
          return `کد تخفیف ${Number(reward.reward_value).toLocaleString(
            "en-US"
          )} تومان`;
        case "coupon_percentage":
          return `کد تخفیف ${reward.reward_value} درصد`;
        case "nothing":
          return "پوچ";
        case "score":
          return ` ${reward.reward_value} امتیاز`;
        case "bon":
          return ` ${reward.reward_value} بن`;
        default:
          return "نامشخص";
      }
    },

    async onCanvasRotateStart() {
      try {
        const response = await axios.post("/customer/games/spin");
        console.log(response, "response");

        if (!response.data.success) {
          alert(
            response.data.message || "خطایی رخ داده است، لطفا دوباره تلاش کنید."
          );
          return;
        }

        const reward =
          response.data.data.gamificationResponse.gameRewardObjects;

        let winningPrize = reward[0].id;

        if (
          response.data.data.gamificationResponse.reward?.coupon_percentage[0]
            ?.code
        ) {
          this.codePesentCopon =
            response.data.data.gamificationResponse.reward?.coupon_percentage[0]?.code;
        } else if (
          response.data.data.gamificationResponse.reward?.coupon_flat[0]?.code
        ) {
          this.codePesentCopon =
            response.data.data.gamificationResponse.reward?.coupon_flat[0]?.code;
        }

        this.alertName = this.getRewardName(reward[0]);
        this.$refs.child.spinWheel(winningPrize);
      } catch (error) {
        console.error(error);
        alert(
          error.response?.data?.message || "خطای غیرمنتظره‌ای رخ داده است."
        );
      }
    },

    onRotateEnd() {
      if (this.alertName === "پوچ") {
this.winRain = false
        this.modalData = {
          title: "😞 متأسفیم!",
          message:
            "متأسفانه این بار چیزی برنده نشدی، اما شانست رو دوباره امتحان کن! 🎡",
        };
      } else {
        this.modalData = {
          title: "🎉 تبریک! 🎉",
          message: `شما برنده ${this.alertName} شدید! 🎁`,
          discountCode: this.codePesentCopon || "",
        };
      }
      this.showModal = true;
    },
    visible(toggle) {
      this.showModal = toggle;
      console(this.showModal, "this is a this.showModal : ");
    },
  },

  watch: {
    showModal(newVal) {
      this.$nextTick(() => {
        this.showModal = newVal;
      });
    },
  },
  mounted() {
    console.log(this.isLogin);
    if (this.isLogin) {
      this.$store.dispatch("front/createSpinGame");
    }
  },
  watch: {
    isLogin(newValue, oldValue) {
      if (newValue !== oldValue && newValue == true) {
        this.$store.dispatch("front/createSpinGame");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#game-container{
  position: relative;
  min-height: 100vh;
}

.main {
  width: 90%;
  margin: 0 auto ;
  padding: 3% 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  //position: relative;
  #background-game{
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      right: 0;
    }
  }
}

.boldText {
  font-weight: 800;
}

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 20px;
  font-weight: 700;
}
.figure-error {
  width: 25%;
}
.figure-error img {
  width: 100%;
}

.isLogin {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 30px auto;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.costGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.externalBtn {
  width: 250px;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  padding: 16px 20px;
  margin: 20px 3px 6px;
  text-align: center;
  background-color: #ffffff;
  text-decoration: none;
  color: #000;
  white-space: nowrap;
  z-index: 0;
  border-radius: 4px;
}

.hb-fill-bottom::before {
  position: absolute;
  content: "";
  background: rgb(252, 204, 212) none repeat scroll 0% 0%;
  transition-duration: 0.3s;
  z-index: -1;
  inset: 0px auto auto 0px;
  width: 100%;
  height: 0px;
  opacity: 1;
  border-radius: 4px;
}

.hb-fill-bottom:hover::before {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 4px;
}
.text-error {
  font-size: 21px;
  text-align: center;
}

.box-cost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
}

.navWheel {
  width: 90%;
  padding: 10px 0;
  margin: 0px auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.navWheel figure {
  margin: 0;
}

.navWheel figure img {
  max-width: 66px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translate(-50%, 0);

}

.topbtn {
  background-color: #ffffff;
  color: hotpink;
  padding: 10px;
  font-weight: 800;
  border-radius: 10px;
}
.topbtn:hover {
  background-color: #fcd0e9;
  color: hotpink;
  padding: 10px;
  font-weight: 800;
  border-radius: 10px;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .main {
    width: 100%;
    display: flex;
    margin: 60px auto auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .externalBtn {
    width: 208px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    padding: 9px 16px;
    margin: 0;
    text-align: center;
    background-color: #ffffff;
    text-decoration: none;
    color: #000;
    white-space: nowrap;
    z-index: 0;
    border-radius: 4px;
  }
  .figure-error {
    width: 100%;
  }
  .box-cost {
    margin-top: 30px;
  }
}
</style>
<style>
canvas * {
  font-family: "iran-sans" !important;
}
</style>
