<template>
  <section
      class="user-information p-3 bg-white   box-shaddow20">
    <WithdrawWalletModal />
    <WithdrawWalletShowModal :withdraw="showWithdraw" />
    <h4 class="text-center ">برداشت از کیف پول</h4>
    <LineGrow open-width="90%"/>
    <button data-toggle="modal" data-target="#withdrawModal" class="walletOptionBtn">
      برداشت
    </button>
    <template v-if="status">
      <b-row id="skeletonImage">
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img>
        </b-col>
      </b-row>
      <br />
      <br />
    </template>
    <template v-else>
      <h5 class="text-center w-100 mt-5" >کاربر گرامی ؛ امکان برداشته مبالغ هدیه از کیف پول وجود ندارد</h5>
      <h5 class="text-center w-100 mt-1" >مبلغ درخواستی شما طی 24 ساعت الی 48 ساعت برای شما واریز می شود.</h5>

      <div id="favoriteProduct">
        <table class="table">
          <template v-if="withdraw.data.length != 0">
            <thead>
            <tr id="trHeadWalletTabel" class="text-color-444 fontsize-small">
              <th class="hideIn768" scope="col">شناسه</th>
              <th scope="col">مبلغ(تومان)</th>
              <th scope="col">وضعیت</th>
              <th scope="col">تاریخ تراکنش</th>
              <th scope="col">جزئیات</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="withdrawItem in withdraw.data" class="trHover" :key="withdrawItem.id">
              <th  scope="row">
                {{ withdrawItem.id }}
              </th>
              <td>
                {{ withdrawItem.amount | price }}
              </td>
              <td scope="row">
                <span class="badge text-white"
                      :class="{'badge-primary': withdrawItem.status == 'pending',
                      'badge-success': withdrawItem.status == 'paid',
                      'badge-danger': withdrawItem.status == 'canceled',}">
                  {{ getTranslation(withdrawItem.status) }}
                </span>
              </td>
              <td style="word-spacing: 3px">
                {{ moment(withdrawItem.created_at).format('HH:mm YYYY/MM/DD') }}
              </td>
              <td>
                <button @click="show(withdrawItem)" class="btn btn-primary">مشاهده</button>
              </td>
            </tr>
            </tbody>
          </template>
          <div class="notContent" v-else>
            <h4>اطلاعاتی برای نمایش وجود ندارد</h4>
          </div>
        </table>
        <section id="mainPaginationBlog"
                 v-if="withdraw && (withdraw.last_page > 1)" class="orders-list-pagination text-center mb-4 mt-0 p-2 bg-color-fa">
          <section id="listPagination" class="list-pagination orders-list-pagination text-center py-2">
            <pagination :limit="1" :data="withdraw" @pagination-change-page="changePage"
                        :disabled="status" class="justify-content-center">
              <span slot="prev-nav">
                <ZPrevSvg />
              </span>
              <span slot="next-nav">
                <ZNextSvg />
              </span>
            </pagination>
          </section>
        </section>

      </div>
    </template>
  </section>
</template>

<script>
import LineGrow from "../shared/LineGrow";
import {BRow, BCol, BSkeletonImg} from 'bootstrap-vue'
import WithdrawWalletModal from "@/parts/Front/components/panel/Withdraw/WithdrawWalletModal.vue";
import WithdrawWalletShowModal from "./Withdraw/WithdrawWalletShowModal.vue";
import ZPrevSvg from "../shared/ZPrevSvg";
import ZNextSvg from "../shared/ZNextSvg";

export default {
  name: "Withdraw",
  components: {ZNextSvg, ZPrevSvg, WithdrawWalletShowModal, LineGrow, BRow, BCol, BSkeletonImg, WithdrawWalletModal},
  data() {
    return {
      loading: false,
      allow:false,
      showWithdraw: null
    };
  },
  created() {
    if (this.withdraw == null) {
      this.$store.dispatch(
          "front/getWithdrawFromServer",
          this.$route.query.page != undefined ? this.$route.query.page : "1"
      );
    }
  },
  methods: {
    getTranslation(key) {
      switch (key) {
        case 'paid':
          return 'پرداخت شده'
        case 'pending':
          return 'در انتظار تکمیل'
        case 'canceled':
          return 'شکست خورده'
      }
    },
    show(withdraw) {
      this.showWithdraw = withdraw
      window.$("#withdrawShowModal").modal("show");
    },
    changePage(page) {
      this.$nextTick(() => {
        document.querySelector('.in-user-panel').scrollIntoView({behavior: "smooth"});
      });
      if (this.disabled) {
        return;
      }
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, page: page } })
    },
  },
  watch: {
    "$route.query.page": {
      handler: function (val) {
        let page = val;
        if (this.allow)
          this.$store.dispatch("front/getWithdrawFromServer", page);
      },
      immediate: true,
    },
  },
  computed: {
    status() {
      return this.$store.getters["front/getWithdrawStatus"];
    },
    withdraw() {
      if (this.$store.getters["front/getWithdraw"] == null) {
        return null;
      } else {
        return this.$store.getters["front/getWithdraw"].withdraws;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.allow = true;
    }, 5000);
  },
}
</script>

<style scoped>
.walletOptionBtn {
  margin-bottom: 5px;
  background-color: #35a80b;
  color: white;
  border-radius: 7px;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  right: 30px;
}

.user-information {
  position: relative;
}
</style>
