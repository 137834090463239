<template>
  <div>
    <main class="main product-details-main magezine">
      <!-- <PagesPath :pathTitle1="'مجله بندیتو'" :pathTo1="'/magazine'"></PagesPath> -->
      <div class="container d-flex justify-content-between my-2 headerMagazine">
        <div >
          <router-link to="/">
            <div class="btnGoHome">
فروشگاه بندیتو
            </div>
          </router-link>
        </div>
        <div>
          <AtlasLogo />
        </div>
        <div> </div>
      </div>
      <div class="weblog-list-grid container text-right mt-md-5 mt-3">
        <div class="weblog-list-main">
          <b-skeleton v-if="status"></b-skeleton>
          <div v-else></div>
          <h4 class="mb-4">لیست مجله های بندیتو</h4>

          <div v-if="status">
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
          </div>
          <section v-else class="weblog-list-main-grid">
            <template v-if="blogs.length != 0">
              <Weblog
                v-for="(weblog, index) in blogs.posts.data"
                :key="index"
                :weblog="weblog"
              ></Weblog>
            </template>
            <div v-else>
              <span>پستی برای نمایش وجود ندارد</span>
            </div>
          </section>
          <b-skeleton v-if="status"></b-skeleton>
          <section
            id="mainPaginationBlog"
            v-else
            class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
          >
            <section
              v-if="blogs && blogs.posts != ''"
              id="listPagination"
              class="list-pagination weblog-list-pagination text-center my-4 py-2"
            >
              <pagination
                :limit="1"
                :data="blogs.posts"
                @pagination-change-page="changePage"
                :disabled="status"
                class="justify-content-center"
              >
                <span slot="prev-nav">
                  <ZPrevSvg />
                </span>
                <span slot="next-nav">
                  <ZNextSvg />
                </span>
              </pagination>
            </section>
          </section>
        </div>

        <div class="welog-list-side categorisList">
          <b-skeleton-img v-if="status"></b-skeleton-img>
          <br v-if="status" />
          <template v-else>
            <!-- باکس جستوجو پست -->
            <div class="p-3 bg-white mb-3 box-shaddow20 border-r-20">
              <div class="d-flex justify-content-center">
                <h6 class="mr-2 weight-bold text-color-theme fontsize-medium">
                  جستجوی مطالب
                </h6>
              </div>
              <div id="seatchBlogSection" class="mt-2 border p-2 border-r-15">
                <button
                  @click.prevent="blogSearch()"
                  type="button"
                  class="d-md-inline-block d-none bg-none ml-2"
                >
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3049 4.35233H10.1467C9.86258 4.35233 9.62695 4.1167 9.62695 3.83256C9.62695 3.54842 9.86258 3.3128 10.1467 3.3128H14.3049C14.589 3.3128 14.8246 3.54842 14.8246 3.83256C14.8246 4.1167 14.589 4.35233 14.3049 4.35233Z"
                      fill="#565656"
                    />
                    <path
                      d="M12.2258 6.43142H10.1467C9.86258 6.43142 9.62695 6.1958 9.62695 5.91166C9.62695 5.62752 9.86258 5.39189 10.1467 5.39189H12.2258C12.5099 5.39189 12.7456 5.62752 12.7456 5.91166C12.7456 6.1958 12.5099 6.43142 12.2258 6.43142Z"
                      fill="#565656"
                    />
                    <path
                      d="M8.41599 15.4407C4.50041 15.4407 1.3125 12.2528 1.3125 8.33719C1.3125 4.42161 4.50041 1.2337 8.41599 1.2337C8.70013 1.2337 8.93576 1.46933 8.93576 1.75347C8.93576 2.03761 8.70013 2.27324 8.41599 2.27324C5.06869 2.27324 2.35203 4.99682 2.35203 8.33719C2.35203 11.6776 5.06869 14.4011 8.41599 14.4011C11.7633 14.4011 14.4799 11.6776 14.4799 8.33719C14.4799 8.05305 14.7156 7.81742 14.9997 7.81742C15.2838 7.81742 15.5195 8.05305 15.5195 8.33719C15.5195 12.2528 12.3316 15.4407 8.41599 15.4407Z"
                      fill="#565656"
                    />
                    <path
                      d="M15.6931 16.1337C15.5615 16.1337 15.4298 16.0852 15.3258 15.9812L13.9398 14.5952C13.7388 14.3942 13.7388 14.0616 13.9398 13.8606C14.1408 13.6596 14.4734 13.6596 14.6744 13.8606L16.0604 15.2466C16.2614 15.4476 16.2614 15.7802 16.0604 15.9812C15.9565 16.0852 15.8248 16.1337 15.6931 16.1337Z"
                      fill="#565656"
                    />
                  </svg>
                </button>
                <input type="text" id="seacrchInBlogs" v-model="search" />
                <b-spinner
                  v-if="spinnerSearch"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
            </div>
            <section class="weblog-categories p-3 bg-white mb-3 box-shaddow20">
              <!-- دسته بندی پست ها -->
              <div
                class="d-flex justify-content-center border-bottom-dashed pb-2"
              >
                <h6 class="mr-2 weight-bold text-color-theme fontsize-medium">
                  دسته بندی ها
                </h6>
              </div>
              <ul class="py-3">
                <!-- دریافت از کامپیوت پایین -->
                <li
                  v-for="(category, index) in blogs.category"
                  :key="'category' + index"
                >
                  <router-link
                    class="fontsize-small"
                    :to="{
                      name: 'magazines',
                      params: {
                        id: category.id,
                        slug: weblogSlugGenerator(category.slug),
                      },
                    }"
                  >
                    {{ category.name }}
                  </router-link>
                </li>
              </ul>
            </section>
          </template>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Weblog from "@/parts/Front/components/Weblog";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import PagesPath from "@/parts/Front/components/PagesPath";
import paginationMixin from "@/libraries/mixins/paginationMixin";
import { BSkeleton, BSkeletonImg, BCol, BRow } from "bootstrap-vue";
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
export default {
  name: "WeblogList",
  components: {
    ZNextSvg,
    ZPrevSvg,
    Weblog,
    PagesPath,
    BSkeleton,
    BSkeletonImg,
    BCol,
    BRow,
    AtlasLogo
  },
  data() {
    return {
      search: null,
      spinnerSearch: false,
      selectedCategoryId: null,
    };
  },
  mixins: [paginationMixin],
  mounted() {
    //get height of advertisting1
    const advertisting1 = document.querySelectorAll(".advertisting1")[0];
    if (advertisting1) {
      advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
    }

    window.addEventListener("resize", function () {
      const advertisting1 = document.querySelectorAll(".advertisting1")[0];
      if (advertisting1) {
        advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
      }

      // get height of articles/
      const articleImg = document.querySelectorAll(".article-img");
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    });

    // get height of articles/
    let bodyWidth = document.body.offsetWidth;
    const articleImg = document.querySelectorAll(".article-img");
    if (bodyWidth < 767) {
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    }
  },

  computed: {
    // دریافت اطلاعات از ویو ایکس برای لیست پست ها
    blogs() {
      return this.$store.getters["front/getAllBlog"];
    },
    status() {
      // برای نمایش لودر ها
      return this.$store.getters["front/getStatusAllBlog"];
    },
  },
  methods: {
    showBlogs(link) {
      if (link == null) return;
      // page=             پیدا کردن عدد مقابل کوئری
      // نمایش در یو آر ال و صدا زدن تابع فتچ
      let pageIndex = link.search("page=");
      let page = link.slice(pageIndex + 5);
      this.$router.replace({ name: "magazines", query: { page: page } });
      // this.$route.query.page=page;
      // let data={
      //   page:page
      // };
      // if(this.$route.params.id){
      //   data.category=this.$route.params.id
      // }
      //  this.$store.dispatch("front/getAllBlogFromServer",data);
    },
    blogSearch() {
      this.spinnerSearch = true;

      if (this.search != null) {
        //با کلیک روی دکمه سرچ جستوجو در بلاگ ها صورت میگیرد
        this.$store.dispatch("front/searchInBlog", this.search);
      } else {
        // در صورت خالی بودن فوکز شود روی اینپوت
        document.getElementById("seacrchInBlogs").focus();
        this.spinnerSearch = false;
      }
    },
    weblogSlugGenerator(Text) {
      if (!Text) {
        return "مشاهده";
      }
      return Text;
    },
    // تابع زیر برای اضافه کردن متا تگ و بالا بردن سئو است
  },
  watch: {
    blogs(newVal, oldVal) {
      if (newVal != oldVal) {
        //برای غیر فعالسازی اسپینر هنگام سرچ
        this.spinnerSearch = false;
      }
    },
    $route: {
      handler: function (val) {
        const data = {
          magazine: true,
        };
        if (val.query.page) {
          data.page = val.query.page;
        }
        if (val.params.id) {
          data.category = this.$route.params.id;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      },
      immediate: true,
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.blogs.posts.data
            ? this.blogs.posts.data[0].name
            : false,
        },
        {
          property: "og:title",
          content: this.blogs.posts.data[0]
            ? this.blogs.posts.data[0].name
            : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped>

#seatchBlogSection {
  display: flex;
}
#hrSearch {
  margin-top: 3px;
}
#seacrchInBlogs {
  flex-grow: 10;
}
/* @media (max-width:991px){
  #mainPaginationBlog{
    display: none!important;
  }
} */
/* افزودن استایل به کامپوننت اضافه شده  */
.pagination {
  justify-content: center;
  flex-direction: row;
}

/*تعمیر قالب   */
#mainPaginationBlog {
  background: none !important;
}
#listPagination {
  background: none !important;
}
.welog-list-side.categorisList section {
  /* box-shadow: 0 0 10px #20202075; */
  position: sticky;
  top: 0;
}
.headerMagazine {
  background-color: #f4f4f4;
  padding: 15px;
}
.btnGoHome{
  border:1px solid var(--color-main-2);
  padding: 10px;
  border-radius: 10px;
  color: var(--color-main-2);
}
.btnGoHome:hover{
  background-color: var(--color-main-2);
  transition:0.5s;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .welog-list-side.categorisList section {
    position: relative;
  }
  .weblog-list-grid {
    display: flex;
    flex-direction: column;
  }
  .weblog-list-main {
    order: 2;
  }
  .welog-list-side.categorisList {
    order: 1;
  }
}
/* .welog-list-side.categorisList li * {
  color: black;
  font-weight: bold;
  stroke: #c26431;
  stroke-width: 2px;
  transition: all 1s;
}*/
.welog-list-side.categorisList li *:hover {
  color: var(--color-theme);
}
</style>
<style>
body:has(.magezine){
  background-image: unset !important;
}

</style>