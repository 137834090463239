<template>
  <section id="AllCategoriesSection" v-if="categories && categories.length">
    <h1
      class="fontsize-regular font-w-800 text-color-grey text-center pt-5 px-2"
    >
      دسته بندی محصولات بندیتو
    </h1>
    <div class="container">
      <div
        class="pt-5 pb-5"
        :class="
          category.children && category.children.length
            ? 'w-100'
            : 'single-categories'
        "
        v-for="(category, index) in categories"
        :key="index"
      >
        <h2
          class="fontsize-regular font-w-800 text-color-grey text-center mb-5"
        >
          {{ category.title }}
        </h2>
        <div
          class="w-100 d-flex align-items-center border-r-45 categories-children-container"
          :class="{
            'swiper-container': mediaQueries.mobileSize,
            'w-max': category.children.length < 3,
          }"
          v-if="category.children && category.children.length"
        >
          <div
            class="w-100 d-flex align-items-center"
            :class="{ 'swiper-wrapper': mediaQueries.mobileSize }"
          >
            <router-link
              :to="{
                name: 'product.category',
                params: {
                  category: childCategory.id,
                  slug: childCategory.slug,
                },
                query: query,
              }"
              class="d-flex align-items-center flex-column categories-children swiper-slide position-relative"
              :class="
                category.children && category.children.length
                  ? 'width-25'
                  : '' + ' ' + mediaQueries.mobileSize
                  ? 'swiper-slide'
                  : ''
              "
              v-for="(childCategory, index) in category.children"
              :key="index"
            >
              <img
                class="w-100 h-100"
                v-if="childCategory.image && childCategory.image.length"
                :src="imageResolver(childCategory)"
                :alt="childCategory.title"
              />
              <h3
                class="fontsize-small font-w-800 text-color-grey position-absolute categories-title"
              >
                {{ childCategory.title }}
              </h3>
            </router-link>
          </div>
        </div>
        <div class="border-r-45 categories-children-container" v-else>
          <router-link
            :to="{
              name: 'product.category',
              params: { category: category.id, slug: category.slug },
              query: query,
            }"
            class="d-flex align-items-center flex-column categories-children position-relative"
            :class="
              category.children && category.children.length ? '' : 'w-100'
            "
          >
            <img
              class="w-100 h-100"
              style="border-radius: 45px !important"
              v-if="category.image && category.image.url"
              :src="category.image.url"
              :alt="category.title"
            />
            <h3
              class="fontsize-small font-w-800 text-color-grey position-absolute bg-none categories-title"
            >
              {{ category.title }}
            </h3>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle";
export default {
  name: "AllCategoties",
  inject: ["mediaQueries"],
  props: {
    query: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initAllCategories();
  },
  watch: {
    categories(newValue) {
      if (newValue) {
        this.initAllCategories(true);
      }
    },
  },
  computed: {
    categories() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].categories;
      } else {
        return null;
      }
    },
  },
  methods: {
    initAllCategories(kill = false) {
      this.initSwiper("allCategoriesSwiper", kill, () => {
        new Swiper("#AllCategoriesSection .swiper-container", {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: false,
          centeredSlides: false,
          centeredSlidesBounds: false,
          speed: 2000,
          autoplay: {
            delay: 3500,
            disableOnInteraction: false,
          },
        });
      });
    },
    imageResolver(category) {
      if (category && category.image && category.image.length) {
        //get last array child image
        return `https://api.benedito.ir/storage/${
          category.image[category.image.length - 1].lg
        }`;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
#AllCategoriesSection .categories-children-container {
  background-color: #ffffff;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
}
#AllCategoriesSection .categories-children-container a:first-child img {
  border-radius: 0 45px 45px 0;
}

#AllCategoriesSection
  .categories-children-container
  a:not(:nth-child(3n)):last-child
  img {
  border-radius: 45px 0 0 45px;
}

#AllCategoriesSection .categories-children:not(:first-child) {
  border-right: 1px solid #e5e5e5;
}
#AllCategoriesSection .categories-children {
  position: relative;
  width: 25%;
  /* overflow: hidden; */
}
#AllCategoriesSection .categories-title {
  top: 105%;
}
.w-max {
  box-shadow: unset !important;
}
.w-max div {
  justify-content: center !important;
  gap: 16px;
}
.w-max div img {
  border-radius: 45px !important;
}
.w-max div a {
  border-right: unset !important;
}
@media screen and (min-width: 992px) {
  #AllCategoriesSection .categories-children:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(235, 111, 167, 0.5009978991596639) 85%
    );
  }
}
@media screen and (min-width: 769px) {
  #AllCategoriesSection .single-categories {
    display: inline-block;
    margin-left: 1rem;
    width: 23%;
  }
  #AllCategoriesSection .width-25 {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  #AllCategoriesSection .categories-children {
    position: relative;
    width: 50%;
    /* border-bottom: 1px solid #e5e5e5; */
  }
  #AllCategoriesSection .single-categories {
    display: inline-block;
    margin-left: 1rem;
    width: 45%;
  }
  #AllCategoriesSection .categories-children-container {
    background-color: transparent;
    box-shadow: unset;
  }
  #AllCategoriesSection .swiper-container {
    padding-bottom: 50px;
  }
  #AllCategoriesSection .categories-children-container a:first-child img {
    border-radius: 45px;
  }
  #AllCategoriesSection .categories-children-container a img {
    border-radius: 45px;
  }
  /* #AllCategoriesSection .categories-title {
    top: unset;
    bottom: 0;
  } */
  .w-max div {
    justify-content: unset !important;
  }
}
@media screen and (max-width: 540px) {
  #AllCategoriesSection .categories-children {
    position: relative;
    width: 100%;
  }
  #AllCategoriesSection .single-categories {
    display: inline-block;
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  #AllCategoriesSection h1 {
    font-size: 26px;
  }
}
</style>
